import React, { useState, useEffect, Fragment, useRef } from "react";
import { Dialog, Transition, Menu } from "@headlessui/react";
import "./dao.css";
import { FadeIn } from "react-slide-fade-in";
// import Img1 from "../Assets/Img/logo.png";
import Img2 from "../Assets/Img/image20.png";
import Img3 from "../Assets/Img/image21.png";
import Img4 from "../Assets/Img/image1.svg";
import Current from "../Components/Current";
import Submit from "../Components/Submit";
import Stake from "../Components/Stake";
import Unstake from "../Components/Unstake";
import Donate from "../Components/donate";
import Connect from "../Components/Connect";
import { FaPowerOff } from "react-icons/fa";
import { useWallet } from "@txnlab/use-wallet";
import { getCountdownDate, getTimeUsed } from "../Controller/Countdown";
import { useTimer } from "../Controller/Constants";
import Claim from "../Components/Claim";
import NextProposal from "../Controller/NextProposal";
import { Previous } from "../Components/Previous";
import { powerByStakeDuration, powerByVote } from "../Controller/VotePower";
import { app_id, fame } from "../utils";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dao() {
  //Get User Wallet Address from Local Storage
  const [address, setAddress] = useState("CONNECT");
  const { activeAddress } = useWallet();
  const {
    walletBalance,
    algouBalance,
    stakedAmount,
    pv,
    p,
    uv,
    dv,
    days,
    hours,
    minutes,
    seconds,
    treasuryBalance,
    algoBalance,
    totalStake,
    lastVote,
    endTime,
    coolDown,
    duration,
    proposal_Type,
    proposer,
    birthday,
    numVote,
  } = useTimer();
  const account = activeAddress;

  let [isOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (!account) {
      setAddress("CONNECT");
    } else {
      setAddress(account);
    }
  }, [account, setAddress]);

  const cancelButtonRef = useRef(null);

  //GetCoundownDate
  const date = getCountdownDate(days, hours, minutes, seconds);

  const result = uv + dv;
  const upvote = ((uv / result) * 100).toFixed(2);

  //Treasury Fame Balance
  const realFameBal = (treasuryBalance - totalStake).toFixed(2);

  //theshold
  const threshold = ((30 / 100) * totalStake).toFixed(2);

  //Current Time
  const currentTime = Date.now() / 1000;

  //End of cool Time
  const endOfCoolDown = endTime + coolDown;

  //Voting Power
  function truncateDecimals(number, digits) {
    const multiplier = Math.pow(10, digits);
    return Math.floor(number * multiplier) / multiplier;
  }
  // console.log(stakedAmount);
  // console.log(numVote);
  // console.log(birthday);
  // console.log(currentTime);

  const power1 = powerByVote(stakedAmount, numVote);
  const power2 = powerByStakeDuration(
    stakedAmount,
    birthday,
    currentTime,
    power1
  );
  const number = Number(power2);
  const votingPower = truncateDecimals(number, 2);
  // console.log(votingPower);
  return (
    <>
      <div>
        <section className="dao-hero pt-20 p-10 lg:p-20 lg:pb-10 md:pb-10 md:p-20">
          <div style={{ maxWidth: "1400px", margin: "0 auto" }}>
            <div className="pt-20">
              <FadeIn
                from="right"
                positionOffset={400}
                triggerOffset={200}
                delayInMilliseconds={0}
              >
                <p className="text-white  lg:pb-0 sm:pb-0 lg:text-right text-left dao-hero-heading">
                  FAME DAO (testNet)
                </p>
              </FadeIn>
            </div>
            <Menu
              as="div"
              className="relative justify-right lg:text-right text-right"
            >
              <div>
                <Menu.Button className="inline-flex  gap-x-1.5 text-white px-3 py-2 text-sm font-semibold  ">
                  TESTNET
                  <ChevronDownIcon
                    className="-mr-1 h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-30 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="https://algofame.org/dao"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm text-center"
                          )}
                        >
                          MAINNET
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="./"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm text-center"
                          )}
                        >
                          TESTNET
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            <div className="lg:mt-0 mt-5">
              <FadeIn
                from="left"
                positionOffset={400}
                triggerOffset={200}
                delayInMilliseconds={0}
              >
                <div className="dao-card md:mt-8">
                  <div className="lg:p-5 p-5  lg:pl-10 card-content">
                    <div className="grid lg:grid-cols-4 grid-cols-2 gap-4">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="inline-flex item-center grid"
                        href="https://app.tinyman.org/#/swap?asset_in=0&asset_out=142838028"
                      >
                        <button className="dao-btn inline-flex item-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-2 text-base font-semibold leading-7 text-white bg-secondary ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                          <img src={Img4} alt="" className="dao-icon" />
                          <span className="ml-3 dao-name">BUY</span>
                        </button>
                      </a>
                      <Stake />
                      <Unstake />
                      <Donate />
                    </div>
                    <div className="dao-hero-text ml-5">
                      <p className="text-secondary">
                        FAME balance - {walletBalance} FAME
                      </p>
                      <p className="text-primary">
                        Your stake - {stakedAmount} FAME
                      </p>
                      <p className="text-secondary">
                        Voting Power - {votingPower}
                      </p>

                      <p className="text-black">
                        ALGO balance - {algouBalance} ALGO
                      </p>
                      {/* <p>
                        Hover over this text to see a tooltip:
                        <span class="tooltip">
                          Hover me
                          <span class="tooltiptext">This is a tooltip</span>
                        </span>
                      </p> */}
                    </div>
                  </div>
                </div>
              </FadeIn>
            </div>
          </div>
        </section>

        <section className="p-10 lg:p-20 lg:pt-10 md:pt-10 md:p-20">
          <div
            className="lg:grid grid-cols-5 gap-20"
            style={{ maxWidth: "1400px", margin: "0 auto" }}
          >
            {/* Show remaining Time for Next Proposal after Endtime  */}
            {currentTime > endOfCoolDown ? (
              <>
                <Submit />
                <Current type="none" />
              </>
            ) : currentTime > endTime && currentTime < endOfCoolDown ? (
              <NextProposal />
            ) : currentTime < endOfCoolDown ? (
              <>
                <Submit type="none" />
                <Current />
              </>
            ) : (
              <Submit />
            )}

            <div className="col-span-2 lg:pt-0 pt-10">
              <div className="vote-sec">
                <div className="tape treasury text-white p-5 bg-secondary">
                  <p>the FAME treasury</p>
                  <img src={Img4} alt="logo" />
                </div>
                <div className="text-primary lg:p-5 p-5 lg:pb-5 treasury-bal">
                  <p>
                    Algo balance: <span>{algoBalance} ALGO</span>
                  </p>
                  <p>
                    Fame balance: <span>{realFameBal} FAME</span>
                  </p>
                  <p>
                    Total Stake: <span>{totalStake} FAME</span>
                  </p>
                  <p>
                    Threshold: <span>{threshold} </span>
                  </p>
                  {/* <p>
                    AN Other ASA: <span>367,200,579</span>
                  </p> */}
                </div>
                <div className="token lg:flex p-5 lg:p-5 lg:pt-0">
                  <p>App {app_id} </p>
                  <p className="lg:block hidden"> • </p>
                  <p> Token $FAME {fame}</p>
                </div>
              </div>

              <Previous />
            </div>
          </div>
        </section>
        <section className="p-10 lg:p-20 lg:pt-10 md:pt-10 md:p-20 about-sec">
          <div
            className="lg:grid grid-cols-9 gap-5"
            style={{ maxWidth: "1400px", margin: "0 auto" }}
          >
            <div className="col-span-6">
              <p className="about">Introducing the FAME DAO</p>
              <p className="mt-3 about-text">
                We're excited to present the FAME DAO, a decentralized
                autonomous organization created for our community to have a
                direct influence on the future of the FAME token allocation. Be
                part of the decision-making process and play an active role in
                determining how FAME tokens are allocated and used.
              </p>

              {/* <button className="flex mt-5 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-1.5 text-base font-semibold leading-7 text-white bg-secondary ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                More about the FAME DAO
              </button>
              <button className="flex mt-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-4 py-1.5 text-base font-semibold leading-7 text-white bg-black ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                DAO Constitution and rules
              </button> */}
            </div>
            <p className="how text-primary  mt-5 col-span-9">How it works</p>
            {/* <div className="col-span-3 mt-3 sm:mt-0">
              <p className="font-bold text-left">A - Maximize your influence</p>
              <p className="about-text mt-2">
                Here’s how you can maximize your influence in the FAME DAO:
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">1. Staking Rewards:</span> Each
                month of staking your $FAME in the DAO, your voting power
                increases by 10%. Boost your voting power by up to 50% after
                staking for 5 months. We value our long-term supporters!
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">2. Voting Rewards :</span> Your
                engagement matters! Every vote you cast boosts your voting power
                by another 10%, up to an extra 50% after 5 votes. We reward
                active participation!
              </p>
              <p className="about-text mt-2">
                So, if you've staked 10 $FAME for 5 months and voted 5 times,
                your voting power doubles to 20!
              </p>
            </div>

            <div className="col-span-3 lg:mt-0 mt-5">
              <p className="font-bold text-left"> B - Follow the rules</p>
              <p className="about-text mt-2">
                Please note the following rules when participating in the FAME
                DAO:
              </p>
              <ol className="about-text mt-2">
                <li>
                  1. Make sure you have staked $FAME before submitting a
                  proposal.
                </li>
                <li>
                  2. Proposals can only request a maximum of 10% of FAME or Algo
                  to be withdrawn.
                </li>
                <li>
                  3. Clearly explain why the community should approve a
                  withdrawal from the Treasury to your wallet.
                </li>
                <li>4. Submitting a proposal costs 0.05 $FAME. </li>
                <li>5. Voting on a proposal is just 0.01 $FAME.</li>
                <li>
                  6. Upvotes must be greater than downvotes for a proposal to
                  pass
                </li>
                <li>
                  7. The total number of votes (upvotes and downvots) must be
                  greater than 30% of the total stake (threshold), the proposal
                  passes.
                </li>
              </ol>
            </div>

            <div className="col-span-3 lg:mt-0 mt-5">
              <p className="font-bold text-left"> C - Important timelines </p>
              <p className="about-text mt-3">
                Plus, there are some important timelines to be aware of:
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">Voting Period:</span> Once a
                proposal is live, the community has 7 days to cast their votes.
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">Token Claim:</span> If the proposal
                is approved, the proposer has 24 hours after the end of the
                voting period to claim the tokens. (3minutes for the TestNet
                version).
              </p>
              <p className="about-text mt-2">
                Join us in shaping the future of FAME's token allocation. Your
                voice, your vote, your community!
              </p>
            </div> */}
            <div className="col-span-3 mt-3 sm:mt-0">
              <p className="font-bold text-left">A - Amplify Your Influence</p>
              <p className="about-text mt-2">
                Here’s how you can maximize your influence in the FAME DAO:
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">Loyalty Power-Up:</span> Stake your
                $FAME and each month boosts your voting potency by 10%. A
                stellar 50% awaits after a 5-month allegiance. Dedication's the
                key!
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">Action Points:</span> Cast a vote,
                get a 10% voting power bump! Max out at a whopping 50% with 5
                votes. So, staking 10 $FAME for 5 months + 5 votes = doubled
                voting might of 20!
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">
                  Heads up! Unstaking? You'll reset those sweet bonuses.
                </span>
              </p>
            </div>

            <div className="col-span-3 lg:mt-0 mt-5">
              <p className="font-bold text-left"> B - DAO Decorum</p>
              <p className="about-text mt-2">
                Engaging in the FAME DAO? Here's your rulebook:
              </p>
              <ol className="about-text mt-2">
                <li>1.Propose only after staking some $FAME.</li>
                <li>
                  2. A proposal can eye a max of 10% withdrawal, be it $FAME or
                  $ALGO.
                </li>
                <li>
                  3. Lay out your 'Why?' - justify the Treasury withdrawal.
                </li>
                <li>4. Setting a proposal costs you 0.05 $FAME. </li>
                <li>5. Casting your vote deducts a mere 0.01 $FAME.</li>
                <li>
                  6. A proposal sails through only with more upvotes than
                  downvotes.
                </li>
                <li>
                  7. 30% of the total stake FAME must be participating in the
                  vote.
                </li>
              </ol>
            </div>

            <div className="col-span-3 lg:mt-0 mt-5">
              <p className="font-bold text-left">
                C - Clock's Ticking: Key Timelines
              </p>
              <p className="about-text mt-3">
                Plus, there are some important timelines to be aware of:
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">Decision Window:</span> A live
                proposal? 7 days to gather community verdict (Testnet: just 5
                minutes).
              </p>
              <p className="about-text mt-2">
                <span className="font-bold">Token Claim:</span> Post a
                successful proposal, you've 24 hours (or 5 Testnet minutes) to
                claim your tokens.
              </p>
              <p className="about-text mt-2">
                Rally with us in forging FAME's token destiny. You speak, you
                seal, you shape!
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
