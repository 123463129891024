import React from "react";
import Img9 from "../Assets/Img/image9.png";
export default function SolutionBox() {
  return (
    <div className="relative flex">
      <p
        className="flex uppercase text-white absolute"
        style={{
          fontSize: "22px",
          fontWeight: "400",
          lineHeight: "28px",
          letterSpacing: "0.550000011920929px",
          textAlign: "left",
          zIndex: "1",
          transform: "translate(70px, 15px)",
        }}
      >
        the solution
      </p>
      <img
        src={Img9}
        alt="Solution"
        className="relative flex"
        style={{ zIndex: "0" }}
      />
    </div>
  );
}
