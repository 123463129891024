export const algosdk = require("algosdk");

export let client = new algosdk.Algodv2(
  "",
  "https://testnet-api.algonode.cloud",
  ""
);
// export let client = new algosdk.Algodv2(
//   "",
//   "https://mainnet-api.algonode.cloud",
//   ""
// );

// export let app_id = 225262568;
export let app_id = 403911773;
// export let app_id = 552027377;
export let fame = 148814612; //testnet
// export let fame = 142838028; // The Nainnet I found

export const creatorAccount = JSON.parse(localStorage.getItem("walletconnect"))
  ?.accounts[0];
export let duration = 300;
