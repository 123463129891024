import React, { useState, useEffect } from "react";
import { app_id } from "../utils";
import Utils from "./Utils.ts";
import App from "../App";

const algosdk = require("algosdk");
const algodToken = "";

const algodServer = "https://testnet-api.algonode.cloud"; //Server for TestNet
// const algodServer = "https://mainnet-api.algonode.cloud"; //Server for Mainnet

const algodPort = "";

const algodClient = new algosdk.Algodv2(algodToken, algodServer, algodPort);

export const refreshBalance = async (
  account,
  setwalletBalance,
  setAlgoBalance,
  setStakedAmount,
  setNumVote,
  setBirthday,
  setLastVote
) => {
  if (!account) {
    setwalletBalance("XXX");
    setAlgoBalance("XXX");
    setStakedAmount("XXX");
    return;
  }

  try {
    const balance = await algodClient.accountInformation(account).do();
    // //console.log(balance);
    const fameAssetId = 148814612; // Assest id for TestNet
    // const fameAssetId = 142838028; // AssetId for Mainnet
    // //console.log(balance);
    const fame = await balance.assets.find(
      (item) => item["asset-id"] === fameAssetId
    );
    const algo = await balance.amount;
    const fameBalance = await fame.amount;

    const userState = await balance["apps-local-state"];
    // //console.log(userState);
    const state = userState.find((app) => app.id === app_id);

    //amount user staked
    const stakedValue = state?.["key-value"].find((item) => item.key === "cw==")
      ?.value?.uint;

    //when user voted last in seconds
    const LastVote = state?.["key-value"].find((item) => item.key === "bHY=")
      ?.value?.uint;

    //timestamp of first vote
    const birthday = state?.["key-value"].find((item) => item.key === "Yg==")
      ?.value?.uint;

    //number of times user has voted
    const numVote = state?.["key-value"].find((item) => item.key === "bnY=")
      ?.value?.uint;

    // //console.log(numVote, birthday, LastVote, stakedValue);
    setNumVote(numVote);
    setBirthday(birthday);
    setLastVote(LastVote);
    setwalletBalance(Number(fameBalance * 10e-3).toFixed(2));
    setAlgoBalance(Number(algo * 10e-7).toFixed(2));
    setStakedAmount(Number(stakedValue * 10e-3).toFixed(2));
  } catch (error) {
    //console.log(error);
  }
};

export const appState = async (
  setPv,
  setP,
  setUv,
  setDv,
  setDays,
  setHours,
  setMinutes,
  setSeconds,
  setTotalStake,
  setEndTime,
  setCoolDown,
  setDuration,
  setProposalType,
  setProposer,
  setThreshold
) => {
  try {
    const state = (await algodClient.getApplicationByID(app_id).do()).params[
      "global-state"
    ];

    // const stateA = (await algodClient.getApplicationByID(app_id).do()).params[
    //   "global-state"
    // ];

    const readableState = Utils.getReadableState(state);
    // //console.log(readableState);
    setPv(readableState.pv.number * 10e-3);

    const pt = await readableState.pt.string;
    if (pt === "n") {
      const pr = readableState.p.string;
      const prefix = "ref//";
      if (pr.startsWith(prefix)) {
        const proposalType = "re";
        const p = pr.substring(prefix.length);
        setP(p);
        setProposalType(proposalType);
      } else {
        setP(readableState.p.string);
        setProposalType(readableState.pt.string);
      }
    } else {
      setProposalType(readableState.pt.string);
      setP(readableState.p.string);
    }

    setUv(readableState.uv.number * 10e-3);
    setDv(readableState.dv.number * 10e-3);
    setEndTime(readableState.et.number);
    setTotalStake(Number((await readableState.tl.number) * 10e-3).toFixed(2));
    setCoolDown(readableState.o.number);
    setDuration(readableState.r.number);
    setThreshold(await readableState.t.number);
    setProposer(readableState.pd.address);

    const targetUnixTime = readableState.et.number;
    const currentTime = Date.now();
    const targetTime = new Date(targetUnixTime * 1000); // Convert Unix time to milliseconds
    const timeDifference = targetTime - currentTime;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    function addLeadingZero(number) {
      return number < 10 ? `0${number}` : `${number}`;
    }

    // Format each component with leading zeros to ensure two digits
    const formattedDays = addLeadingZero(days);
    const formattedHours = addLeadingZero(hours);
    const formattedMinutes = addLeadingZero(minutes);
    const formattedSeconds = addLeadingZero(seconds);

    setDays(formattedDays);
    setHours(formattedHours);
    setMinutes(formattedMinutes);
    setSeconds(formattedSeconds);
  } catch (error) {
    //console.log(error);
  }
};

export const refreshTreasuryBalance = async (
  account,
  setwalletBalance,
  setAlgoBalance,
  setStakedAmount
) => {
  if (!account) {
    setwalletBalance("XXX");
    setAlgoBalance("XXX");
    setStakedAmount("XXX");
    return;
  }

  try {
    const balance = await algodClient.accountInformation(account).do();
    //console.log(balance);
    const fameAssetId = 148814612;
    // const fameAssetId = 142838028; // AssetId for Mainnet
    const algo = balance.amount;
    const fameBalance = balance.assets.find(
      (item) => item["asset-id"] === fameAssetId
    )?.amount;

    setwalletBalance(Number(fameBalance * 10e-3).toFixed(2));
    setAlgoBalance(Number(algo * 10e-7).toFixed(2));
  } catch (error) {
    //console.log(error);
  }
};
