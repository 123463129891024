import React from "react";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import Mix from "../Assets/Img/home.png";
import PhoneGroup from "../Assets/Img/phone-group.jpg";
import Img4 from "../Assets/Img/image4.png";
import Img5 from "../Assets/Img/image5.png";
import Img6 from "../Assets/Img/image6.jpg";
import Img10 from "../Assets/Img/image10.png";
import Img11 from "../Assets/Img/image11.png";
import Img12 from "../Assets/Img/image12.png";
import Img13 from "../Assets/Img/image13.png";
import Img16 from "../Assets/Img/image16.png";
import Img17 from "../Assets/Img/image17.png";
import Img22 from "../Assets/Img/image22.png";
import Img23 from "../Assets/Img/image23.png";
import iemo from "../Assets/Img/iemo.png";
import Person1 from "../Assets/Img/person1.jpg";
import iconFood from "../Assets/Img/icon-food.png";
import iconChat from "../Assets/Img/icon-chat.png";
import iconBorrow from "../Assets/Img/icon-borrow.png";
import iconDao from "../Assets/Img/icon-dao.png";
import iconJobs from "../Assets/Img/icon-jobs.png";
import iconLend from "../Assets/Img/icon-lend.png";
import iconMap from "../Assets/Img/icon-map.png";
import iconSwap from "../Assets/Img/icon-swap.png";
import Solution from "../Components/Solution";
import { FadeIn } from "react-slide-fade-in";
import Button from "../Components/Button";
import Page from "../Components/Page";
import GoApp from "../Components/GoApp";
import Algo from "../Assets/Img/algorand2.svg";
import Form from "../Components/Form";
import PropTypes from "prop-types";
import { Features } from "../Components/Features";
import YoutubeEmbed from "../Components/YoutubeEmbed";
import AppStore from "../Components/AppStore";
import Playstore from "../Components/PlayStore";
export default function Home() {
  return (
    <section className="">
      <section>
        <div className="isolate bg-white relative hero bg-no-repeat ">
          <main className="">
            <div className="inline-flex absolute lg:bottom-8 md:bottom-0 bottom-0 right-0">
              <p className="align-bottom absolute lg:bottom-8 md:bottom-9 lg:-left-10 md:-left-10 bottom-3.5 -left-14 font-bold text-white">
                Built on
              </p>
              <a href="https://algorand.com">
                <img
                  src={Algo}
                  alt="Algorand Logo"
                  className=" lg:h-28 md:h-28 h-14"
                />
              </a>
            </div>

            <div className="">
              <div
                className="p-10 lg:p-20 md:p-20"
                style={{ maxWidth: "1400px", margin: "0 auto" }}
              >
                <div>
                  <div className="pt-20">
                    <FadeIn
                      from="right"
                      positionOffset={400}
                      triggerOffset={200}
                      delayInMilliseconds={0}
                    >
                      <p className="text-white pt-10 pb-10 lg:pb-0 sm:pb-20 text-left hero-heading">
                        <span
                          className="hero-heading"
                          style={{ fontSize: "60px" }}
                        >
                          the farmer’s social app
                        </span>
                      </p>
                    </FadeIn>
                    <FadeIn
                      from="left"
                      positionOffset={400}
                      triggerOffset={200}
                      delayInMilliseconds={0}
                    >
                      <h6 className="hero-list mt-5 text-left hidden lg:block">
                        <span>Food Autonomy and Mutual Empowerment.</span>
                      </h6>
                    </FadeIn>
                    <div className="hidden pt-20 lg:block">
                      <FadeIn
                        from="top"
                        positionOffset={200}
                        triggerOffset={200}
                        delayInMilliseconds={0}
                      >
                        <p className="mt-6 font-normal text-lg text-left leading-8 text-black-600 sm:text-left max-w-lg">
                          Leveraging the Algorand blockchain, FAME activate food
                          transparency and champions global financial inclusion.
                        </p>

                        <div className="mt-8 flex gap-x-4 sm:justify-left pt-20">
                          <AppStore />
                          <Playstore />
                          <Button />
                        </div>
                      </FadeIn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </section>
      <section className=" lg:hidden p-10 bg-ash ">
        <p className="hero-content">
          Leveraging the Algorand blockchain, FAME activate food transparency
          and champions global financial inclusion.
        </p>
        <div className="  mt-5 ">
          <AppStore />
        </div>
        <div className="mt-5 ">
          <Playstore />
        </div>
        <div className="mt-5 ">
          <Button />
        </div>
      </section>
      <section style={{ maxWidth: "1400px", margin: "0 auto" }}>
        <div className="md:grid md:grid-cols-2 text-left sm:grid sm:grid-cols-2 p-10 lg:p-20 md:p-20">
          <div
            style={{
              fontSize: "38px",
              fontWeight: "bold",
              position: "relative",
            }}
          >
            <FadeIn
              from="left"
              positionOffset={200}
              triggerOffset={200}
              delayInMilliseconds={0}
            >
              <p>
                <span className="text-primary">free</span> marketplace.
              </p>
              <p>
                <span className="text-primary">free</span> financial services.
              </p>
              <p>
                <span className="text-secondary">sustainable</span> technology.
              </p>
            </FadeIn>
            <FadeIn
              from="left"
              positionOffset={200}
              triggerOffset={200}
              delayInMilliseconds={300}
            >
              <p
                className="mt-10"
                style={{
                  fontSize: "18px",
                  lineHeight: "32px",
                  fontWeight: "400",
                }}
              >
                FAME is built on Algorand, the world's most carbon-neutral
                blockchain.
              </p>
            </FadeIn>
            <FadeIn
              from="left"
              positionOffset={200}
              triggerOffset={200}
              delayInMilliseconds={500}
            >
              <img className="mt-5 mb-5" src={Mix} alt="" />
            </FadeIn>
          </div>
          <div className="lg:pl-20">
            <img src={PhoneGroup} alt="" />
            <div className="lg:grid lg:grid-cols-4 md:grid md:grid-cols-4 lg:gap-1 md:gap-1 ">
              <div className="hidden lg:block md:block">
                <img src={Img4} alt="" />
              </div>
              <div className="col-span-3">
                <p className="leading-8">
                  Make the FAME app central to your day-to-day life. Use it to
                  trade, share, find work, collaborate, fundraise, advertise,
                  earn, borrow, connect.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-[#F7F7F7] ">
        <div
          className="lg:grid lg:grid-cols-2 gap-20 text-left p-10 lg:p-20 md:p-20"
          style={{ maxWidth: "1400px", margin: "0 auto" }}
        >
          <div className="lg:mb-0 md:mb-10 mb-10">
            <p
              className="text-primary lg:mb-0 md:mb-0 mb-5"
              style={{
                fontSize: "38px",
                fontWeight: "bold",
                position: "relative",
              }}
            >
              Bridging Decentralized Finance
              <span className="text-secondary"> with Agriculture</span>
            </p>
            <p className="mt-10 purpose-head">Decentralized Empowerment</p>
            <p className=" mt-2 purpose-text">
              FAME repositions power dynamics. Farmers become crucial
              decision-makers via DAO tools, while consumers gain insights into
              their food's origins and production.
            </p>
            <p className="mt-10 purpose-head">Financial Inclusion & Fluidity</p>
            <p className=" mt-2 purpose-text">
              Facilitating direct peer-to-peer transactions with minimal fees on
              the secure Algorand Blockchain, FAME ensures farmers maximize
              earnings, and consumers benefit from clarity in their
              transactions.
            </p>
          </div>
          <div>
            <YoutubeEmbed embedId="FgAOG2xGIfI" />
          </div>
        </div>
        <div
          className="lg:grid lg:grid-cols-2 gap-20 text-left p-10 pt-0 lg:p-20 lg:pt-0 md:p-20 md:pt-0 md:pb-0"
          style={{ maxWidth: "1400px", margin: "0 auto" }}
        >
          <div className="lg:block hidden ">
            <YoutubeEmbed embedId="EtsIkW-cMtw" />
          </div>

          <div className="lg:mt-0 md:mt-0 mb-10 lg:mb-0 md:mb-10 ">
            <p className="purpose-head">Market Access & Equity</p>
            <p className=" mt-2 purpose-text">
              FAME repositions power dynamics. Farmers become crucial
              decision-makers via DAO tools, while consumers gain insights into
              their food's origins and production.
            </p>
            <p className="mt-10 purpose-head">Community Governance</p>
            <p className=" mt-2 purpose-text">
              Decentralized governance means community-driven project direction
              and resources allocation, ensuring solutions are rooted in actual
              needs.
            </p>
          </div>
          <div className="lg:hidden block ">
            <YoutubeEmbed embedId="EtsIkW-cMtw" />
          </div>
        </div>
        <div
          className="lg:grid lg:grid-cols-2 gap-20 text-left p-10 pt-0  lg:p-20 lg:pt-0 md:p-20"
          style={{ maxWidth: "1400px", margin: "0 auto" }}
        >
          <div className="lg:mb-0 md:mb-10 mb-10">
            <p className="purpose-head">Global Impact</p>
            <p className=" mt-2 purpose-text">
              FAME accelerates payments to producers in crises, addressing
              issues from financial access in volatile zones to food
              distribution challenges, aligning closely with multiple
              Sustainable Development Goals.
            </p>
            <p className="mt-10 purpose-head">Collaboration</p>
            <p className=" mt-2 purpose-text">
              Supported by prominent entities like the Algorand Foundation and
              UN organizations, FAME amplifies its impact as a collaborative
              global initiative.
            </p>
          </div>
          <div>
            <YoutubeEmbed embedId="CUXH0uGDlrI" />
          </div>
        </div>
        <div
          className=" text-left p-10 pt-0  lg:p-20 lg:pt-0 md:p-20 md:pt-0"
          style={{ maxWidth: "1400px", margin: "0 auto" }}
        >
          <p
            style={{
              fontSize: "38px",
              fontWeight: "bold",
              position: "relative",
            }}
          >
            <span className="text-primary"> FAME isn’t just an app; </span>
            <span className="text-secondary">
              it’s a transformative movement.
            </span>
          </p>
          <p
            style={{
              fontSize: "38px",
              fontWeight: "bold",
              position: "relative",
            }}
          >
            Merging tech with the tangible requirements of farmers and
            consumers, it seeks to overhaul the agricultural value chain,
            promoting equity, clarity, and empowerment throughout.
          </p>
        </div>
      </section>

      <section className="bg-[#000000]">
        <div style={{ maxWidth: "1400px", margin: "0 auto" }} className="">
          <div className="lg:grid  lg:grid-cols-2 md:grid-cols-2 ">
            <div className="">
              <img src={Img5} alt="" />
            </div>
            <div className="lg:pt-20 md:pt-20">
              <div
                className=" p-10  lg:p-20 md:p-20 relative"
                style={{
                  position: "relative",
                  display: "flex",
                }}
              >
                <img
                  src={Img6}
                  alt=""
                  className=" absolute bottom-0 right-0 overflow-hidden "
                  style={{
                    position: "relative",
                    display: "flex",
                    transform: "translate(0,0)",
                    zIndex: "0",
                  }}
                />

                <p
                  className="text-white  "
                  style={{
                    fontSize: "38px",
                    fontWeight: "600",
                    lineHeight: "48px",
                    letterSpacing: "0.47256410121917725px",
                    textAlign: "left",
                    position: "absolute",
                    transform: "translate(0,0)",
                    zIndex: "0",
                  }}
                >
                  <FadeIn
                    from="right"
                    positionOffset={200}
                    triggerOffset={200}
                    delayInMilliseconds={0}
                  >
                    <span className="text-secondary ">on our way!</span>
                    <br /> the FAME app is now on Mainnet
                  </FadeIn>

                  <div className="pt-20">
                    <FadeIn
                      from="right"
                      positionOffset={200}
                      triggerOffset={200}
                      delayInMilliseconds={0}
                    >
                      <div className="mb-5">
                        <AppStore />
                      </div>

                      <Playstore />
                    </FadeIn>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="p-10 lg:p-20 md:p-20 text-left"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <div className="grid lg:grid-cols-2 ">
          <div className="lg:pr-20 md:pr-20 lg:pt-0">
            <p
              style={{
                fontSize: "38px",
                fontWeight: "600",
                lineHeight: "48px",
                letterSpacing: "0.47256410121917725px",
              }}
            >
              <span className="text-secondary">work. sell. borrow. earn. </span>
              <span className="text-primary">CONNECT.</span>
            </p>
            <p
              className="leading-8 pt-8 lg:pt-10 "
              style={{
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "31px",
                letterSpacing: "0.44999998807907104px",
                textAlign: "left",
              }}
            >
              Every FAME user can immediately open savings accounts in a non
              custodial way to have access to microcredit in a few simple steps.
            </p>
          </div>

          <div className=" lg:pl-10 pt-10 lg:pt-0">
            <div>
              <p
                className="text-left"
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  lineHeight: "31px",
                  letterSpacing: "0.44999998807907104px",
                }}
              >
                At FAME , we believe that we can empower smallholder farmers
                with a tailor made social marketplace for food and jobs;
                reducing food insecurity, improving employment rates and
                activating financial inclusion.
              </p>
              <p
                className="pt-10"
                style={{
                  fontSize: "38px",
                  fontWeight: "600",
                  lineHeight: "48px",
                  letterSpacing: "0.47256410121917725px",
                }}
              >
                <span className="text-primary">join us</span> to enjoy the
                benefits of <span className="text-secondary">DEFI </span>for
                everyone!
              </p>
            </div>
          </div>
        </div>
      </section>
      <Solution />
      <section className="bg-[#F7F7F7]">
        <div
          className="lg:grid lg:grid-cols-2 pb-20"
          style={{ maxWidth: "1400px", margin: "0 auto" }}
        >
          <Features
            title=" The Food Marketplace"
            image={iconFood}
            direction="left"
            text="A curated space where farmers and consumers converge,
            ensuring fresh, locally-sourced produce is accessible to
            communities."
          />
          <Features
            title="The Job Marketplace"
            image={iconJobs}
            direction="left"
            text="With a broad spectrum of job opportunities, our platform
            connects employers with potential candidates, fueling local
            economic growth."
          />
          <Features
            title="The FAME Map"
            image={iconMap}
            direction="left"
            text="An Innovative feature designed to reconnect individuals on
            the ground, fostering peer-to-peer interactions and
            catalyzing community growth."
          />
          <Features
            title=" Swap (Decentralized Exchange)"
            image={iconSwap}
            direction="left"
            text="Facilitating secure, swift, and transparent asset trading,
            our integrated DEX promises a trading experience devoid of
            intermediaries."
          />
          <Features
            title=" Chat-based Payment System"
            image={iconChat}
            direction="right"
            text="Simplifying transactions, our inventive system allows
            payments directly within chat interfaces, merging
            communication and commerce."
          />
          <Features
            title="DAO Capabilities"
            image={iconDao}
            direction="right"
            text="Facilitating secure, swift, and transparent asset trading,
            our integrated DEX promises a trading experience devoid of
            intermediaries."
          />
          <Features
            title="Borrowing and automatic loans"
            image={iconBorrow}
            direction="right"
            text="Designed for flexibility, this protocol provides users with
            tailored borrowing options, ensuring financial fluidity when
            needed."
          />
          <Features
            title="Lending and Passive Income"
            image={iconLend}
            direction="right"
            text="A comprehensive platform that enables users to optimize
            their assets, offering avenues for savings and earning
            passive income."
          />
        </div>
      </section>
      <section>
        <div
          className="lg:grid lg:grid-cols-2 text-left p-10 lg:p-20 md:p-20"
          style={{ maxWidth: "1400px", margin: "0 auto" }}
        >
          <div className="  lg:pr-20">
            <p
              className="text-primary"
              style={{
                fontSize: "38px",
                fontWeight: "600",
                lineHeight: "48px",
                letterSpacing: "0.47256410121917725px",
              }}
            >
              UN Sustainable Development Goals and FAME’s mission.
            </p>
            <p
              className="pt-10 mr-5"
              style={{
                color: "#323232",
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "31px",
                letterSpacing: "0.44999998807907104px",
              }}
            >
              The UN's{" "}
              <a href="https://sdgs.un.org/goals">
                <span className="text-secondary">
                  {" "}
                  Sustainable Development Goals (SDGs)
                </span>{" "}
              </a>
              are a universal mandate aimed at eradicating poverty, safeguarding
              our planet, and ensuring prosperity and peace for all. At the
              heart of FAME's mission are the foundational pillars of these
              goals: Eliminating Poverty and Eradicating Hunger. Through FAME,
              we create pathways for financial inclusion, and we also empower
              users to tap into passive income streams through the DEFI
              capabilities of the Algorand ecosystem.
            </p>
            <p
              className="pt-10 mr-5"
              style={{
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "31px",
                letterSpacing: "0.44999998807907104px",
              }}
            >
              FAME is a beacon of hope, ushering in a new era where economic
              opportunities are within everyone's reach, especially those
              historically marginalized from mainstream financial services. Our
              platform revolutionizes peer-to-peer payments in developing
              regions – a transformative move that can reshape entire economies.
            </p>
            <p
              className="pt-10 mr-5"
              style={{
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "31px",
                letterSpacing: "0.44999998807907104px",
              }}
            >
              But our vision doesn't stop there. By addressing the complexities
              of food distribution and supply-chain dynamics, FAME aims to
              redefine the balance of power within the food and agriculture
              sectors, ensuring equitable access, transparency, and
              sustainability for all. Join us in this transformative journey
              towards a brighter, inclusive future.
            </p>
          </div>
          <div>
            <div
              className="grid lg:grid-cols-2 gap-10 mt-10 lg:mt-0"
              style={{
                fontSize: "14px",
                fontStyle: "normal",
                fonWeight: "400",
                linHeight: "24px",
                letterSpacing: "0.35px",
                color: "#323232",
              }}
            >
              <div>
                <img src={Img22} alt="" className="w-full" />
                <p className="pt-5">
                  “575 million people could be living in extreme poverty by
                  2030. A surge in action and investment to enhance economic
                  opportunities, improve education and extend social protection
                  to all, particularly the most excluded, is crucial to
                  delivering on the central commitment to end poverty and leave
                  no one behind.”
                  <span className="text-secondary">
                    <a href="https://www.undp.org/sustainable-development-goals/no-poverty">
                       Read more…
                    </a>
                  </span>
                </p>
              </div>
              <div>
                <img src={Img23} alt="" className="w-full" />
                <p className="pt-5">
                  “The number of people facing hunger and food insecurity has
                  been rising since 2015. To achieve zero hunger by 2030, urgent
                  coordinated action and policy solutions are imperative to
                  address entrenched inequalities, transform food systems, and
                  invest in sustainable agricultural practices.”
                  <span className="text-secondary">
                    <a href="https://www.undp.org/sustainable-development-goals/zero-hunger">
                       Read more…
                    </a>
                  </span>
                </p>
              </div>
            </div>
            <div
              className="grid lg:grid-cols-2 gap-10 pt-10"
              style={{
                fontSize: "14px",
                fontStyle: "normal",
                fonWeight: "400",
                linHeight: "24px",
                letterSpacing: "0.35px",
                color: "#323232",
              }}
            >
              <div>
                <img src={Person1} alt="" className="w-full" />
                <img src={iemo} alt="" className="w-full mt-10" />
              </div>
              <div>
                <p className="mt-5 lg:mt-0  ">
                  Bilal, FAME’s Founder is also an
                  <a href="https://www.iemo.int/">
                    <span className="text-secondary"> IEMO </span>
                  </a>
                  Commissioner for Food Security and is profoundly immersed in
                  IEMO’s endeavors. He actively engages in their activities,
                  focusing on the prevention, preparedness, mitigation, and
                  recovery from both natural and man-made emergencies. Bilal
                  also collaborates extensively with other UN branches. Through
                  his dual roles with FAME and IEMO, he is dedicated to averting
                  hunger and propelling global food security forward.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="" style={{ maxWidth: "1400px", margin: "0 auto" }}>
        <div
          className="lg:grid lg:grid-cols-10 text-left p-10 lg:p-20 md:p-20"
          style={{ maxWidth: "1400px", margin: "0 auto" }}
        >
          <div className="col-span-3 lg:pt-20 md:pt-20 pt-0">
            <img src={Img17} alt="" />
          </div>
          <div className="col-span-7 pt-10 lg:p-20  ">
            <p
              className="text-primary"
              style={{
                fontSize: "38px",
                fontWeight: "600",
                lineHeight: "48px",
                letterSpacing: "0.47256410121917725px",
              }}
            >
              FAME Institute
            </p>
            <p
              className="pt-10"
              style={{
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "31px",
                letterSpacing: "0.44999998807907104px",
              }}
            >
              It's by changing small communities that we change the whole world,
              making it a better place for everyone. The Fame Institute is an
              initiative that will bring together technological, financial,
              social and educational efforts to support local communities.
              Integrating students, scientists and technicians from different
              academic backgrounds who will join forces to build a fair and
              equitable future, using their knowledge to link the real world and
              blockchain technology.
            </p>
            <Page />
          </div>
        </div>
      </section> */}
    </section>
  );
}
