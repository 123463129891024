import React from "react";

export default function Terms() {
  return (
    <>
      <div
        className="bg-secondary"
        style={{ height: "160px", paddingTop: "-50px" }}
      ></div>
      <section
        className=" lg:p-20 p-10"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <div className=" hero-list mt-10 text-left">FAME Terms of Service</div>
        <div className="text-left mt-10">
          The FAME Android, iOS, browser-based apps, and websites are published,
          owned, and operated by ALGOFAME, a french corporation, its affiliates
          and related entities (“FAME”, “Company,” “we,” “us,” and “our”). These
          Terms of Use (the “Terms”) govern the user's (“user” “you” “your”)
          access and use of our mobile apps, browser-based apps, websites, and
          applications whether accessed via computer, mobile device or otherwise
          (individually and collectively, the “Application,”) as well as any
          products and services provided by FAME (the “FAME Services”) (the
          Application, together with the FAME Services, collectively referred to
          as the “Service”).
        </div>
        <div className="text-left mt-10">
          <p className="text-bold"> 1. ACCEPTANCE OF AGREEMENT </p>
          <p className="mt-5">
            These terms of service (the "Terms") set forth the legally binding
            terms and conditions that govern your use of the Service, including
            all related tools, mobile applications, web applications,
            decentralized applications, smart contracts, and application
            programming interfaces (collectively, the "Platform"). The Terms
            apply to your use of the Platform for any purpose, including but not
            limited to viewing historical data, transaction information,
            statistics, and information on other activities taking place on the
            network. By accessing or using the Platform in any manner, you
            acknowledge that you have read, understood, and agree to be bound by
            these Terms.
          </p>
          <p className="mt-5">
            As a user of the Platform, you have the right to access and view
            historical data, transaction information, and other activities
            taking place on the platform. You also have the responsibility to
            comply with all rules and guidelines set forth in these Terms.{" "}
          </p>
          <p className="mt-5">
            It is important that you carefully read and understand these Terms
            before using the Platform. Your use of the Platform constitutes
            acceptance of these Terms and creates a legally binding agreement
            between you and the company operating the Platform.
          </p>
          <p className="mt-5 text-bold">
            PLEASE BE AWARE THAT THESE TERMS OF SERVICE REQUIRE THE USE OF
            ARBITRATION (SECTION 13.4) ON AN INDIVIDUAL BASIS TO RESOLVE
            DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS, AND ALSO LIMIT
            THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.
          </p>
          <p className="mt-5">
            By accessing, browsing, submitting information to and/or using the
            Application, or by signing into the Application you accept and agree
            to be bound and abide by these Terms and our Privacy Policy,
            incorporated herein by reference, and to comply with all applicable
            laws including, without limitation, all federal, state and local tax
            and tariff laws, regulations, and/or directives. Accordingly, under
            Article 6 of the General Data Protection Regulation, or “GDPR,”
            users in the European Union acknowledge and consent to our
            processing of personal data as necessary for the performance of
            these Terms, any applicable agreements, and use of the Application.
            If you do not agree to the Terms, please do not use the Application.
            The Terms of Service are referred to herein as the “Agreement.”
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold"> 2. AMENDMENTS</p>
          <p className="mt-5">
            Company reserves the right to amend this Agreement, Company's
            Privacy Policy described in Section below, at any time with
            reasonable notice, as determined by Company in its sole discretion.
            Company will post notice of any amendment on the Service. You should
            check this Agreement, and Company's Privacy Policy regularly for
            updates. By continuing to use the Platform or Service after such
            notice is provided, you accept and agree to such amendments. If you
            do not agree to any amendment to any of these agreements, you must
            stop using the Platform and Service. If you have any questions about
            the terms and conditions in this Agreement, or Company's Privacy
            Policy, please contact us at legal@algofame.org
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold">3. DEFINITIONS AND INTERPRETATION</p>
          <p className="text-bold">3.1. Defined Terms.</p>
          <p className="mt-5">
            Unless the context requires otherwise, capitalized terms in this
            Agreement shall have the following meanings:
          </p>
          <p className="mt-3">
            “Affiliate” means, with respect to a party, any person, firm,
            corporation, partnership (including, without limitation, general
            partnerships, limited partnerships, and limited liability
            partnerships), limited liability company, or other entity that now
            or in the future, directly controls, is controlled with or by or is
            under common control with such party.
          </p>
          <p className="mt-3">
            “FAME” means the FAME APP which provides non-custodial account
            services and information, as well as allow other activities taking
            place on a blockchain network.
          </p>
          <p className="mt-3">
            “Third-Parties API” means open-source API provided by third-parties
            which are utilized to support all FAME products and technologies.
          </p>
          <p className="mt-3">
            “Applicable Law” means the laws of Nevis, as the same may be amended
            and in effect from time to time during the Term
          </p>
          <p className="mt-3">
            “Business Day” means a day other than a Saturday, Sunday, or other
            day on which commercial banks in the United Kingdom are authorized
            or required to close.
          </p>
          <p className="mt-3">“NFT” means Non-Fungible Token.</p>
          <p className="mt-3">
            Profile Information” means the information you provide to us to
            register for the Service, including as applicable, blockchain
            account address, name and address, as well as the username and
            password that allow you to access the Service, as such information
            shall change from time to time.
          </p>
          <p className="mt-3">
            “Other Wallet” means any non FAME blockchain-based electronic
            wallet, which allows Users to purchase and store cryptocurrencies,
            and sign/engage in transactions on a blockchain.
          </p>

          <p className="text-bold mt-10"> 3.2. Interpretation.</p>
          <p className="mt-5">
            References to Sections and Appendices are to be construed as
            references to the Sections of, and Appendices to, this Agreement,
            unless otherwise indicated. The singular includes the plural, and
            the plural includes the singular. All references to hereof, herein,
            hereunder and other similar compounds of the word here shall mean
            and refer to this Agreement as a whole rather than any particular
            part of the same. The terms include and including are not limiting.
            Unless designated as Business Days, all references to days shall
            mean calendar days. The use of the word “including” in this
            Agreement to refer to specific examples will be construed to mean
            “including, without limitation” or “including but not limited to”
            and will not be construed to mean that the examples given are an
            exclusive list of the topics covered. The headings, captions,
            headers, footers and version numbers contained in this Agreement are
            intended for convenience or reference and shall not affect the
            meaning or interpretation of this Agreement.
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold"> 4. THE SERVICE</p>
          <p className="text-bold"> 4.1. Purpose of the FAME Services.</p>
          <p className="mt-5">
            FAME Services are provided for the purpose of providing Users with a
            free, client-side interface that allows Users to interact directly
            with the blockchain, while remaining in full control of their
            private keys and funds. The Service, and the Platform are not meant
            to provide any financial advice or indicate any trading opportunity.
            Any reliance you place on such information is strictly at your own
            risk. The Company disclaims all liability and responsibility arising
            from any reliance placed on such content by you or any other visitor
            to our Application, or by anyone who may be informed of any of its
            contents. Any information you provide or that is collected by the
            Company through the Application shall be handled in accordance with
            the Company's Privacy Policy.
          </p>
          <p className="mt-5 text-bold">4.2. Use of the Application. </p>
          <p className="mt-5">
            The Company grants you a non-exclusive license to access and use the
            Platform including the Application and the data, material, content,
            or information herein (collectively, the “Content”) solely for your
            personal use. Your right to access and use the Application shall be
            limited to the purposes described in these Terms unless you are
            otherwise expressly authorized by the Company to use the Application
            for your own commercial purposes. You agree to use the Application
            only for lawful purposes, comply with all rules governing any
            transactions on and through the Application and comply with
            applicable laws.
          </p>
          <p className="mt-5 text-bold">4.2.1. User Account Responsibility.</p>
          <p className="mt-5">
            When you access certain features of the FAME Services, you will be
            able to create a FAME and/or access a FAME to perform a variety of
            transactions. You will receive a key and set up a password, but you
            do not and will not create an account with FAME. You also will not
            provide access to your funds, tokens, or coins (“funds”) which are
            not on FAME. All funds are on the blockchain itself, and we do not
            control them. No data leaves your computer or your browser. FAME
            does not collect or hold your keys or information, and FAME cannot
            access accounts; recover keys, passwords, or other information;
            reset passwords; or reverse transactions.
          </p>
          <p className="text-bold">
            YOU ARE SOLELY RESPONSIBLE FOR YOUR USE OF THE SERVICE, INCLUDING
            WITHOUT LIMITATION FOR STORING, BACKING-UP, AND MAINTAINING THE
            CONFIDENTIALITY OF YOUR KEYS, PASSWORDS, AND INFORMATION, AND FOR
            THE SECURITY OF ANY TRANSACTIONS YOU PERFORM USING THE SITE. YOU
            EXPRESSLY RELIEVE AND RELEASE THE COMPANY FROM ANY AND ALL LIABILITY
            AND/OR LOSS ARISING FROM YOUR USE OF THE SERVICES.
          </p>
          <p className="mt-5">
            Prior to using the Service for any purpose, we highly recommend that
            you read our guides for some recommendations on how to be proactive
            about your security. In addition, we recommend that you review the
            additional FAQs, tips, and guidelines provided in our Knowledge
            Center.
          </p>
          <p className="mt-5 text-bold">4.2.2. Additional Considerations</p>
          <p className="mt-3 text-bold">
            4.2.2.1. Transactions Are Recorded on the Public Blockchains.
          </p>
          <p className="mt-5 mb-10">
            Transactions that take place on the Platform are managed and
            confirmed via public blockchains including but not limited to the
            Algorand blockchain. The User understands that its public address on
            the relevant blockchain will be made publicly visible whenever it
            engages in a transaction on the Platform. We neither own nor control
            any of the blockchain network. We choose to interface with, or any
            other third-party site, product, or service that Users might access,
            visit, or use for the purpose of enabling the user to access and
            utilize the various features of the Platform. We will not be liable
            for the acts or omissions of any such third parties, nor will we be
            liable for any damage that a User may suffer as a result of its
            transactions or any other interaction with any such third parties.
          </p>
          <p className="mt-3 text-bold">4.2.2.2. Transaction Fees.</p>
          <p className="mt-5 mb-10">
            All transactions on the Platform are facilitated by smart contracts
            existing on a blockchain network. Blockchain networks generally
            require the payment of a transaction fee for every transaction, for
            example, the Algorand network requires the payment of a transaction
            fee, (the “Fee”) for every transaction that occurs on the blockchain
            network, and thus every transaction occurring on the Platform
            through a blockchain network. The value of the Fee may change and is
            entirely outside of the control of Company or the Platform. User
            acknowledges that under no circumstances will a transaction on the
            Platform, be invalidated, revocable, retractable, or otherwise
            unenforceable on the basis that the Gas Fee for the given
            transaction was unknown, too high, or otherwise unacceptable to
            User.
          </p>
          <p className="mt-3 text-bold">
            4.2.2.3. Our Use of Account Information.
          </p>
          <p className="mt-5 mb-10">
            Users authorize us to use the account information for all purposes
            related to the Service, provided that such access shall be used
            solely for the purpose of providing the Service.
          </p>
          <p className="mt-5 text-bold">4.3. License. </p>
          <p className="mt-5">
            Subject to this Agreement, the Company grants you a
            non-transferable, non-exclusive, revocable, limited license to use
            and access the Service solely for your own use, and solely as
            permitted by and in compliance with the Terms and Applicable Law.
            Such limited license may be revoked at any time in the Company's
            sole discretion.
          </p>
          <p className="mt-5 text-bold">4.4. Prohibitions and Restrictions</p>
          <p className="mt-5 text-bold">
            4.4.1. Prohibited Uses. <br /> You agree that you will not:
          </p>
          <p className="mt-5">
            Use the Application in any manner that could damage, disable,
            overburden, or impair the Application or interfere with any other
            party's use and enjoyment of it; Attempt to gain unauthorized access
            to any Application account, computer systems or networks associated
            with the Company or the Application;
          </p>
          <p className="mt-5">
            Obtain or attempt to obtain any materials or information through the
            Application by any means not intentionally made available or
            provided by the Company;
          </p>
          <p className="mt-5">
            Use any robot, spider, or other automatic device, process or means
            to access the Application for any purpose, including monitoring or
            copying any of the material on the Application; Introduce any
            viruses, Trojan horses, worms, logic bombs, or other material which
            is malicious or technologically harmful;
          </p>
          <p className="mt-5">
            Attack the Application via a denial-of-service attack or a
            distributed denial-of-service attack; or Impersonate or attempt to
            impersonate the Company, a Company employee, another user or any
            other person or entity (including, without limitation, by using
            email addresses associated with any of the foregoing);
          </p>
          <p className="mt-5">
            License, sell, rent, lease, transfer, assign, distribute, host, or
            otherwise commercially exploit the Service, whether in whole or in
            part, or any content displayed on the Service; Modify, make
            derivative works of, disassemble, reverse compile or reverse
            engineer any part of the Service; or
          </p>
          <p className="mt-5">
            Access the Service in order to build a similar or competitive
            website, product, or service.
          </p>
          <p className="mt-5 text-bold">4.4.2. Restrictions.</p>
          <p className="mt-5">
            Except as expressly stated herein, no part of the Service may be
            copied, reproduced, distributed, republished, downloaded, displayed,
            posted, or transmitted in any form or by any means. Unless otherwise
            indicated, any future release, update, or other addition to
            functionality of the Service shall be subject to this Agreement. All
            copyright and other proprietary notices on the Service (or on any
            content displayed on the Service) must be retained on all copies
            thereof. You will not use the Application or Service for any illegal
            purpose.
          </p>
          <p className="mt-5 text-bold">4.5. Modification.</p>
          <p className="mt-5">
            Company reserves the right, at any time, to modify, suspend, or
            discontinue the Application (in whole or in part) with or without
            notice to you. You agree that Company will not be liable to you or
            to any third party for any modification, suspension, or
            discontinuation of the Application or any part thereof.
          </p>
          <p className="mt-5 text-bold">4.6. No Support or Maintenance.</p>
          <p className="mt-5">
            You acknowledge and agree that Company will have no obligation to
            provide you with any support or maintenance in connection with the
            Application or Service.
          </p>
          <p className="mt-5 text-bold">4.7. Affiliates.</p>
          <p className="mt-5">
            The rights, duties and/or obligations of Company under this
            Agreement may be exercised and/or performed by Company and/or any of
            Company's Affiliates, or any of their subcontractors and/or agents.
            Company acknowledges and agrees that it shall be solely responsible
            for the acts or omissions of Company's Affiliates, and any
            subcontractor or agent of Company or any of Company's Affiliates,
            related to the subject matter hereof. You agree that any claim or
            action arising out of or related to any act or omission of any of
            Company or Company's Affiliates, or any of their respective
            subcontractors or agents, related to the subject matter hereof,
            shall only be brought against Company, and not against any of
            Company's Affiliates, or any subcontractor or agent of Company or
            any of Company's Affiliates.
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold"> 5. PRIVACY</p>
          <p className="mt-5">
            You agree to Randlabs Company's Privacy Policy
            (https://randlabs.io/privacy-policy), which is incorporated by
            reference into this Agreement as if it were set forth herein in its
            entirety. The Privacy Policy describes how we collect, use, and
            disclose information provided by you. By using the Application or
            Service, you agree to, and are bound by, the terms of the Privacy
            Policy.
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold"> 6. COMMUNICATION WITH USERS</p>
          <p className="mt-5">
            You affirm that you are aware and acknowledge that Company is a
            non-custodial service provider and has designed this Platform to be
            directly accessible by the Users without any involvement or actions
            taken by Company or any third-party. The Company does not have a way
            to communicate directly with Users.
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold">
            7. THIRD-PARTY LINKS, PRODUCTS AND APPLICATIONS
          </p>
          <p className="text-bold">7.1. Third-party Sites.</p>
          <p className="mt-5">
            The Application may contain links to websites controlled or operated
            by persons and companies other than the Company (“Linked Sites”),
            including but not limited to any sites related to Web3 projects
            (occasionally hyperlinked as “Official”), Twitter, Instagram,
            TikTok, Discord, Reddit, Telegram, Foundations pages, and Medium.
            Linked Sites are not under the control of the Company, and the
            Company is not responsible for the contents of any Linked Site,
            including without limitation any link contained on a Linked Site, or
            any changes or updates to a Linked Site. The Company is not
            responsible if the Linked Site is not working correctly or for any
            viruses, malware, or other harms resulting from your use of a Linked
            Site. The Company is providing these links to you only as a
            convenience, and the inclusion of any link does not imply
            endorsement by the Company of the site or any association with its
            operators. You are responsible for viewing and abiding by the
            privacy policies and terms of use posted on the Linked Sites. You
            are solely responsible for any dealings with third parties who
            support the Company or are identified on the Application, including
            any delivery of and payment for goods and services. The Company does
            not store any information shared with a Linked Site and is not
            responsible for any personally identifiable information shared with
            any Linked Site.
          </p>
          <p className="text-bold mt-5">7.2. Third-party Applications. </p>
          <p className="mt-5">
            You acknowledge that your access and use of any third-party
            applications or software on our Application and Content, such as
            Know Your Customer “KYC” verification software (the “Third-Party
            Applications”), is at your discretion and risk, and the Company has
            no liability to you arising from your use of the Third-Party
            Applications. The Company hereby disclaims any representation,
            warranty, or guaranty regarding the Third-Party Applications,
            whether expressed, implied or statutory, including, without
            limitation, the implied warranties of merchantability or fitness for
            a particular purpose, and any representation, warranty, or guaranty
            regarding the availability, quality, reliability, features,
            appropriateness, accuracy, completeness, or legality of the
            Third-Party Applications, and you agree to indemnify and hold the
            Company harmless for any direct, indirect, punitive, incidental,
            special, or consequential damages, or any damages whatsoever
            including, without limitation, damages for loss of use, arising out
            of or in any way connected with the use or performance of the
            Third-Party Applications. The Company is not responsible for any
            personally identifiable information shared with any Third-Party
            Applications.
          </p>
          <p className="text-bold mt-5">7.3. Release.</p>
          <p className="mt-5">
            You hereby release and forever discharge the Company (and our
            officers, employees, agents, successors, and assigns) from, and
            hereby waive and relinquish, each and every past, present and future
            dispute, claim, controversy, demand, right, obligation, liability,
            action and cause of action of every kind and nature (including
            personal injuries, death, and property damage), that has arisen or
            arises directly or indirectly out of, or that relates directly or
            indirectly to, the Service (including any interactions with, or act
            or omission of, our partners or any other third party or any
            Third-party Links and Applications). <br />
            <span className="text-bold">
              IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA
              CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH
              STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE
              CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT
              THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER
              MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE
              DEBTOR.”
            </span>
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold">8. INTELLECTUAL PROPERTY</p>
          <p className="text-bold">8.1. Company Intellectual Property.</p>
          <p className="mt-5">
            User acknowledges and agrees that Company (or, as applicable, our
            licensors) own all legal right, title, and interest in and to all
            elements of the Platform. The Company logo, graphics, design,
            systems, methods, information, computer code, software, services,
            “look and feel”, organization, compilation of the content, code,
            data, and all other elements of the Platform (collectively, the
            “Company Materials”) are owned by the Company. The Application,
            Platform, Company Materials, and Content are protected by
            copyrights, trademarks, or are subject to other proprietary rights.
            Accordingly, you are not permitted to use the Application or Content
            in any manner, except as expressly permitted by the Company in these
            Terms. The Application or Content may not be copied, reproduced,
            modified, published, uploaded, posted, transmitted, performed, or
            distributed in any way, and you agree not to modify, rent, lease,
            loan, sell, distribute, transmit, broadcast, or create derivatives
            without the express written consent of the Company or the applicable
            owner. Except as expressly set forth herein, User's use of the
            Platform does not grant User ownership of or any other rights with
            respect to any content, code, data, or other materials that User may
            access on or through the Platform. Company reserves all rights in
            and to the Company Materials not expressly granted to Users in the
            Terms. You may not use any Company Content to link to the
            Application or Content without our express written permission. You
            may not use framing techniques to enclose any Company Content
            without our express written consent. In addition, the look and feel
            of the Site and Content, including without limitation, all page
            headers, custom graphics, button icons, and scripts constitute the
            service mark, trademark, or trade dress of the Company and may not
            be copied, imitated, or used, in whole or in part, without our prior
            written permission.
          </p>
          <p className="mt-5">
            Notwithstanding anything to the contrary herein, You understand and
            agree that You shall have no ownership or other property interest in
            your account, and You further agree that all rights in and to your
            account are and shall forever be owned by and inure to the benefit
            of the Company
          </p>
          <p className="text-bold mt-5">
            8.3. Digital Millennium Copyright Act Compliance
          </p>
          <p className="text-bold">8.3.1. Notification.</p>
          <p className="mt-5">
            We take claims of copyright infringement seriously. We will respond
            to notices of alleged copyright infringement that comply with
            applicable law. If you believe any materials accessible on or from
            the Application or Service infringes your copyright, you may request
            removal of those materials (or access to them) from the Application
            by submitting written notification to Legal@FAMEDigital.com, the
            written notice (the "DMCA Notice") must include substantially the
            following:
          </p>
          <p className="mt-5">
            a physical or electronic signature of a person authorized to act on
            behalf of the owner of an exclusive right that is allegedly
            infringed; identification of the copyrighted work claimed to have
            been infringed, or, if multiple copyrighted works on the Service are
            covered by a single notification, a representative list of such
            works from the Service; identification of the material that is
            claimed to be infringing or to be the subject of infringing activity
            and that is to be removed or access to which is to be disabled, and
            information reasonably sufficient to permit Company to locate the
            material; information reasonably sufficient to permit the Company to
            contact the complaining party, such as an address, telephone number,
            and, if available, an electronic mail address at which the
            complaining party may be contacted; a statement that the complaining
            party has a good faith belief that use of the material in the manner
            complained of is not authorized by the copyright owner, its agent,
            or the law; a statement that the information in the notification is
            accurate; and under penalty of perjury, that the complaining party
            is authorized to act on behalf of the owner of an exclusive right
            that is allegedly infringed. If you fail to comply with all of the
            requirements of Section 512(c)(3) of the DMCA, your DMCA Notice may
            not be effective. Upon removing any allegedly infringing material,
            the Company will notify the alleged infringer of such takedown.
            Please note that under Section 512(f) of the DMCA, any person who
            knowingly materially misrepresents that material or activity is
            infringing may be subject to liability.
          </p>
          <p className="text-bold mt-5">8.3.2. Counter Notification.</p>
          <p className="mt-5">
            If you elect to send our copyright agent a counter notice, to be
            effective it must be a written communication that includes the
            following (please consult your legal counsel or See 17 U.S.C.
            Section 512(g)(3) to confirm these requirements):
          </p>
          <p className="mt-5">
            a physical or electronic signature; identification of the material
            that has been removed or to which access has been disabled and the
            location at which the material appeared before it was removed or
            access to it was disabled;
          </p>
          <p className="mt-5">
            a statement under penalty of perjury that the subscriber has a good
            faith belief that the material was removed or disabled as a result
            of mistake or misidentification of the material to be removed or
            disabled;
          </p>
          <p className="mt-5">
            adequate information by which we can contact you, including your
            name, address, and telephone number; and
          </p>
          <p className="mt-5">
            a statement that the subscriber consents to the jurisdiction of a
            federal district court for the judicial district in which the
            address is located, or if the subscriber's address is outside of the
            United States, for any judicial district in which the Company may be
            found, and that the subscriber will accept service of process from
            the person who provided notification under subsection (c)(1)(C) or
            an agent of such person.
          </p>
          <p className="mt-5">
            The DMCA allows us to restore the removed content if the party
            filing the original DMCA Notice does not file a court action against
            you within ten (10) business days of receiving the copy of your
            counter notice. Please note that under Section 512(f) of the DMCA,
            any person who knowingly materially misrepresents that material or
            activity was removed or disabled by mistake or misidentification may
            be subject to liability.
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold">9. INDEMNIFICATION</p>
          <p className="mt-5">
            You agree to release, indemnify, and hold harmless the Company and
            its Affiliates, and their respective officers, directors, employees
            and agents, harmless from and against any claims, liabilities,
            damages, losses, and expenses, including, without limitation,
            reasonable legal and accounting fees, arising out of or in any way
            related to: (a) your access to, use of, or inability to use the
            Platform, the Application, or Service; (b) your breach of this
            Agreement; (c) your violation of any rights of a third party; (d)
            your violation of any Applicable Law; and (e) any and all financial
            losses you may suffer, or cause others to suffer, due to buying
            selling, trading and/or transferring ALGO, other cryptocurrencies,
            ALGO, or other digital assets whether or not such trades were made
            due to information learned on the Platform or through the Service.
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold">10. ASSUMPTION OF RISK</p>
          <p className="text-bold">
            10.1. User Acknowledges the Risk of Cryptocurrency and Smart
            Contracts.
          </p>
          <p className="mt-5 text-bold">
            YOU REPRESENT AND WARRANT THAT YOU UNDERSTAND AND ARE WILLING TO
            ACCEPT THE RISKS ASSOCIATED WITH CRYPTOGRAPHIC SYSTEMS SUCH AS SMART
            CONTRACTS, PUBLIC BLOCKCHAIN NETWORKS (INCLUDING BUT NOT LIMITED TO
            THE ALGORAND BLOCKCHAIN NETWORK), NON-FUNGIBLE TOKENS, AND THE
            INTERPLANETARY FILE SYSTEM.
          </p>
          <p className="text-bold">
            10.2. Company is Not Responsible for Technical Errors on Any
            Blockchain.
          </p>
          <p className="mt-5 text-bold">
            COMPANY IS NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAINS OR ANY
            OTHER FEATURES OF THE ALGORAND NETWORK OR ANY OTHER BLOCKCHAIN
            NETWORK COMPANY MAY INTERFACE WITH, OR THE METAMASK WALLET OR ANY
            SIMILAR BROWSER OR WALLET ON ANY BLOCKCHAIN NETWORK INCLUDING BUT
            NOT LIMITED TO LATE REPORT BY DEVELOPERS OR REPRESENTATIVES (OR NO
            REPORT AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING THE
            ALGORAND NETWORK OR ANY OTHER BLOCKCHAIN NETWORK COMPANY MAY
            INTERFACE WITH, INCLUDING FORKS, TECHNICAL NODE ISSUES, OR ANY OTHER
            ISSUES HAVING FUND LOSSES AS A RESULT.
          </p>
          <p className="mt-5">
            Upgrades to any blockchain network with which Company may interface,
            a hard fork in any such platform, or a change in how transactions
            are confirmed on any such platform may have unintended, adverse
            effects on all blockchains.
          </p>
          <p className="text-bold mt-5">
            10.3. The User Acknowledges the Risks of the Platform.
          </p>
          <p className="mt-5">
            You acknowledge that the Platform is subject to flaws and
            acknowledge that you are solely responsible for evaluating any
            information provided by the Platform. This warning and others
            provided in this Agreement by Company in no way evidence or
            represent an ongoing duty to alert you to all of the potential risks
            of utilizing or accessing the Platform. The Platform may experience
            sophisticated cyber-attacks, unexpected surges in activity or other
            operational or technical difficulties that may cause interruptions
            to or delays on the Platform. You agree to accept the risk of the
            Platform failure resulting from unanticipated or heightened
            technical difficulties, including those resulting from sophisticated
            attacks, and you agree not to hold us accountable for any related
            losses. The Company will not bear any liability, whatsoever, for any
            damage or interruptions caused by any viruses that may affect your
            computer or other equipment, or any phishing, spoofing or other
            attack.
          </p>
          <p className="text-bold mt-5">
            10.4. Company Does Not Make Any Representations Regarding the Value
            of ALGO, FAME, Cryptocurrencies, NFTS or Other Digital Assets.
          </p>
          <p className="mt-5">
            The prices of blockchain assets are extremely volatile. Fluctuations
            in the price of other digital assets could materially and adversely
            affect the value of cryptocurrency in general, and NFTs which may
            also be subject to significant price volatility. A lack of use or
            public interest in the creation and development of distributed
            ecosystems could negatively impact the development, potential
            utility, or value of digital assets. The Platform, NFTs, and other
            digital assets could be impacted by one or more regulatory inquiries
            or regulatory action. For all of the foregoing reasons, as well as
            for reasons that may not presently be known to the Company, the
            Company makes absolutely no representations or warranties of any
            kind regarding the value of ANY digital assets.
          </p>
          <p className="text-bold mt-5">
            10.5. User Acknowledges Financial Risk of Digital Assets.
          </p>
          <p className="mt-5">
            The risk of loss in trading digital assets can be substantial. You
            should, therefore, carefully consider whether such creating, buying
            or selling digital assets is suitable for you in light of your
            circumstances and financial resources. By using the Platform, you
            represent that you have been, are and will be solely responsible for
            making your own independent appraisal. Under no circumstances shall
            the Company be liable in connection with your use of the Platform in
            connection with your performance of any digital asset transactions.
            Under no circumstances will the operation of all or any portion of
            the Platform be deemed to create a relationship that includes the
            provision or tendering of investment advice. User acknowledges and
            agrees that Company is not a party to any agreement or transaction
            between one or more Users and/or third-parties involving the
            purchase, sale, charge, or transfer of cryptocurrencies, NFTs, or
            any other digital assets.
          </p>
          <p className="text-bold mt-5">10.6. Violations by Other Users.</p>
          <p className="mt-5">
            User irrevocably releases, acquits, and forever discharges the
            Company and its subsidiaries, affiliates, officers, and successors
            for and against any and all past or future causes of action, suits,
            or controversies arising out of another user's violation of these
            Terms.
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold">
            11. LIMITATION OF LIABILITY AND WARRANTY DISCLAIMER
          </p>
          <p className="text-bold">11.1. Limitation of Liability.</p>
          <p className="text-bold mt-5">
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE COMPANY
            (OR OUR AFFILIATES) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
            FINANCIAL LOSS, LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT OF
            SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY,
            INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO
            THESE TERMS OR YOUR USE OF, OR INABILITY TO USE, PLATFORM, THE
            WEBSITE OR THE SERVICE, CONTENT OR INFORMATION ACCESSED VIA THE
            WEBSITE, OR ANY DISRUPTION OR DELAY IN THE PERFORMANCE OF THE
            WEBSITE, OR THE SERVICE EVEN IF THE COMPANY HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SITES OR
            SERVICE IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY
            RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR
            LOSS OF DATA RESULTING THEREFROM. SOME JURISDICTIONS DO NOT ALLOW
            THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
            CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT
            APPLY TO YOU.
          </p>
          <p className="text-bold mt-5">11.2. No Warranties. </p>
          <p className="text-bold mt-5">
            ALL INFORMATION OR SERVICE PROVIDED BY THE COMPANY TO YOU VIA THE
            WEBSITE, INCLUDING, WITHOUT LIMITATION, ALL CONTENT, ARE PROVIDED
            “AS IS” AND “WHERE IS” AND WITHOUT ANY WARRANTIES OF ANY KIND. THE
            COMPANY AND ANY THIRD-PARTY LICENSORS WITH CONTENT ON THE WEBSITE
            EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS, IMPLIED OR
            STATUTORY, INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
            NON-INFRINGEMENT. NOTWITHSTANDING ANY PROVISION CONTAINED HEREIN TO
            THE CONTRARY, THE COMPANY AND ITS THIRD-PARTY LICENSORS MAKE NO
            REPRESENTATION, WARRANTY OR COVENANT CONCERNING THE ACCURACY,
            QUALITY, SUITABILITY, COMPLETENESS, SEQUENCE, TIMELINESS, SECURITY
            OR AVAILABILITY OF THE WEBSITE OR ANY CONTENT POSTED ON OR OTHERWISE
            ACCESSIBLE VIA THE PLATFORM. YOU SPECIFICALLY ACKNOWLEDGE THAT THE
            COMPANY AND ITS THIRD-PARTY LICENSORS ARE NOT LIABLE FOR THE
            DEFAMATORY, OBSCENE OR UNLAWFUL CONDUCT OF OTHER THIRD PARTIES OR
            USERS OF THE WEBSITE AND THAT THE RISK OF INJURY FROM THE FOREGOING
            RESTS ENTIRELY WITH YOU. NEITHER THE COMPANY NOR ANY OF ITS
            THIRD-PARTY LICENSORS REPRESENT, WARRANT OR COVENANT THAT THE
            WEBSITE WILL BE SECURE, UNINTERRUPTED OR ERROR-FREE. THE COMPANY
            FURTHER MAKES NO WARRANTY THAT THE WEBSITE WILL BE FREE OF VIRUSES,
            WORMS OR TROJAN HORSES OR THAT IT WILL FUNCTION OR OPERATE IN
            CONJUNCTION WITH ANY OTHER PRODUCT OR SOFTWARE. YOU EXPRESSLY AGREE
            THAT USE OF THE WEBSITE IS AT YOUR SOLE RISK AND THAT THE COMPANY,
            ITS AFFILIATES SHALL NOT BE RESPONSIBLE FOR ANY TERMINATION,
            INTERRUPTION OF SERVICE, DELAYS, ERRORS, FAILURES OF PERFORMANCE,
            DEFECTS, LINE FAILURES, OR OMISSIONS ASSOCIATED WITH THE WEBSITE OR
            YOUR USE THEREOF. YOUR SOLE REMEDY AGAINST THE COMPANY FOR
            DISSATISFACTION WITH THE WEBSITE OR THE CONTENT IS TO CEASE YOUR USE
            OF THE PLATFORM, WEBSITE AND/OR THE SERVICE. SOME JURISDICTIONS DO
            NOT PERMIT THE EXCLUSION OR LIMITATION OF IMPLIED WARRANTIES, SO THE
            ABOVE EXCLUSION MAY NOT APPLY TO YOU. YOU MAY HAVE OTHER RIGHTS,
            WHICH VARY BY JURISDICTION. WHEN THE IMPLIED WARRANTIES ARE NOT
            ALLOWED TO BE EXCLUDED IN THEIR ENTIRETY, YOU AGREE THAT THEY WILL
            BE LIMITED TO THE GREATEST EXTENT AND SHORTEST DURATION PERMITTED BY
            LAW.
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold">12. TERM AND TERMINATION</p>
          <p className="mt-5">
            Subject to this Section, this Agreement will remain in full force
            and effect while you use the Platform or use the Service (the
            “Term”). We may suspend or terminate your rights to use the Platform
            or use the Service at any time for any reason at our sole
            discretion, including for any use of the Platform or the Service in
            violation of this Agreement. All provisions of the Agreement which
            by their nature should survive, shall survive termination of
            Service, including without limitation, ownership provisions,
            warranty disclaimers, and limitation of liability.
          </p>
        </div>
        <div className="text-left mt-10">
          <p className="text-bold">13. GENERAL TERMS</p>
          <p className="text-bold">13.1. Changes to these Terms of Use.</p>
          <p className="mt-5">
            The Company may update or change these Terms from time to time in
            order to reflect changes in any offered services, changes in the
            law, or for other reasons as deemed necessary by the Company. The
            effective date of any Terms will be reflected in the “Last Revised”
            entry at the top of these Terms. Your continued use of the
            Application after any such change is communicated shall constitute
            your consent to such change(s).
          </p>
          <p className="text-bold mt-5">13.2. Waiver. </p>
          <p className="mt-5">
            The waiver by the Company of a breach of any provision contained
            herein shall be in writing and shall in no way be construed as a
            waiver of any subsequent breach of such provision or the waiver of
            the provision itself.
          </p>
          <p className="text-bold mt-5">13.3. Governing Law & Jurisdiction.</p>
          <p className="mt-5">
            These Terms are governed by the laws of Nevis. You hereby
            irrevocably consent to the exclusive jurisdiction and venue of the
            courts in Nevis, in all disputes arising out of or relating to the
            use of the Application not subject to the Arbitration Agreement
            outlined in 13.4.
          </p>
          <p className="text-bold mt-5">13.4. Dispute Resolution.</p>
          <p className="mt-5">
            Please read the following arbitration agreement in this Section
            (“Arbitration Agreement”) carefully. It requires you to arbitrate
            disputes with the Company and limits the manner in which you can
            seek relief from us. It is part of your contract with the Company
            and affects your rights. It contains procedures of MANDATORY BINDING
            ARBITRATION AND A CLASS ACTION WAIVER.
          </p>
          <p className="text-bold mt-5">
            13.4.1. Applicability of Arbitration Agreement.
          </p>
          <p className="mt-5">
            All claims and disputes (excluding claims for injunctive or other
            equitable relief as set forth below) in connection with the
            Agreement or the use of any product or service provided by the
            Company that cannot be resolved informally shall be resolved by
            binding arbitration on an individual basis under the terms of this
            Arbitration Agreement. Unless otherwise agreed to, all arbitration
            proceedings shall be held in English. This Arbitration Agreement
            applies to you and the Company, and to any subsidiaries, Affiliates,
            agents, employees, predecessors in interest, successors, and
            assigns, as well as all authorized or unauthorized users or
            beneficiaries of services or goods provided under the Agreement.
            This Arbitration Agreement shall apply, without limitation, to all
            disputes or claims and requests for relief that arose or were
            asserted before the effective date of this Agreement or any prior
            version of this Agreement.
          </p>
          <p className="text-bold mt-5">
            13.4.2. Notice Requirement and Informal Dispute Resolution.
          </p>
          <p className="mt-5">
            Before either party may seek arbitration, the party must first send
            to the other party a written Notice of Dispute (“Notice”) describing
            the nature and basis of the claim or dispute, and the requested
            relief. A Notice to the Company should be sent to:
          </p>
          <p className="mt-3">
            ALGOFAME S.A.S. <b /> Email: contact@algofame.org
          </p>
          <p className="mt-3">
            After the Notice is received, you and the Company may attempt to
            resolve the claim or dispute informally. If you and the Company do
            not resolve the claim or dispute within thirty (30) days after the
            Notice is received, either party may begin an arbitration
            proceeding. The amount of any settlement offer made by any party may
            not be disclosed to the arbitrator until after the arbitrator has
            determined the amount of the award, if any, to which either party is
            entitled.
          </p>
          <p className="text-bold mt-5">13.4.3. Arbitration Procedure.</p>
          <p className="mt-5">
            Any dispute, claim, interpretation, controversy, or issues of public
            policy arising out of or relating to the FAME Ecosystem, the
            Application, these Terms, or the Services, including the
            determination of the scope or applicability of this Section 13.4
            will be determined exclusively by arbitration held in Nevis, and
            shall be determined by arbitration administered by the Panama
            Conciliation and Arbitration Centre in accordance with its
            procedural rules. All rights and obligations hereunder shall be
            governed by the Laws of Nevis, without regard to the conflicts of
            law provisions of such jurisdiction. The Parties submit to the
            non-exclusive jurisdiction of the courts of Nevis and any courts
            competent to hear appeals from those courts. For purposes of this
            Section 13.4 “Proceeding” means any complaint, lawsuit, action,
            suit, claim (including a claim of a violation of applicable law), or
            other proceeding at law or in equity, or order or ruling, in each
            case by or before any governmental authority or arbitral tribunal.
          </p>
          <p className="mt-3">
            The Parties irrevocably submit to the exclusive jurisdiction of the
            local courts located in Nevis with respect to this Section 13.4 to
            compel arbitration, to confirm an arbitration award or order, or to
            handle court functions permitted under the Arbitration Procedures.
            The Parties irrevocably waive defense of an inconvenient forum to
            the maintenance of any such action or other Proceeding.
          </p>
          <p className="text-bold mt-5">
            13.4.4. The arbitrator may grant injunctive relief, including
            temporary, preliminary, permanent, and mandatory injunctive relief,
            in order to protect the rights of each party, but will not be
            limited to such relief.
          </p>
          <p className="mt-5">
            This provision for arbitration will not preclude a Party from
            seeking temporary or preliminary injunctive relief (“Provisional
            Relief”) in a court of Law while arbitration Proceedings are pending
            in order to protect its rights pending a final determination by the
            arbitrator, nor will the filing of such an action for Provisional
            Relief constitute waiver by a Party of its right to seek
            arbitration. Any Provisional Relief granted by such court will
            remain effective until otherwise modified by the arbitrator.
          </p>
          <p className="text-bold mt-5">13.4.5. Waiver of Jury Trial.</p>
          <p className="mt-5">
            THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY RIGHTS
            TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY,
            instead electing that all claims and disputes shall be resolved by
            arbitration under this Arbitration Agreement. Arbitration procedures
            are typically more limited, more efficient and less costly than
            rules applicable in a court and are subject to very limited review
            by a court. In the event any litigation should arise between you and
            the Company in any court in a suit to vacate or enforce an
            arbitration award or otherwise, YOU AND THE COMPANY WAIVE ALL RIGHTS
            TO A JURY TRIAL, instead electing that the dispute be resolved by a
            judge.
          </p>
          <p className="text-bold mt-5">
            13.4.6. Waiver of Class or Consolidated Actions.
          </p>
          <p className="mt-5">
            ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION
            AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND
            NOT ON A CLASS BASIS, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER
            CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE
            OF ANY OTHER CUSTOMER OR USER.
          </p>
          <p className="text-bold mt-5">13.4.7. 30-Day Right to Opt Out.</p>
          <p className="mt-5">
            You have the right to opt out of the provisions of this Arbitration
            Agreement by sending written notice of your decision to opt out
            within thirty (30) days after first becoming subject to this
            Arbitration Agreement. Your notice must include your name and
            address, your Wallet address, and an unequivocal statement that you
            want to opt out of this Arbitration Agreement. If you opt out of
            this Arbitration Agreement, all other parts of this Agreement will
            continue to apply to you. Opting out of this Arbitration Agreement
            has no effect on any other arbitration agreements that you may
            currently have, or may enter in the future, with the Company. Mail
            your written notification by certified mail to:
          </p>
          <p className="mt-3">
            ALGOFAME <br />
            Email: contact@algofame.org
          </p>
          <p className="text-bold mt-5">13.4.8. Confidentiality.</p>
          <p className="mt-5">
            All aspects of the arbitration proceeding, including but not limited
            to the award of the arbitrator and compliance therewith, shall be
            strictly confidential. The parties agree to maintain confidentiality
            unless otherwise required by law. This paragraph shall not prevent a
            party from submitting to a court of law any information necessary to
            enforce this Agreement, to enforce an arbitration award, or to seek
            injunctive or equitable relief.
          </p>
          <p className="text-bold mt-5">13.4.9. Severability. </p>
          <p className="mt-5">
            If any part or parts of this Arbitration Agreement are found under
            the law to be invalid or unenforceable by a court of competent
            jurisdiction, then such specific part or parts shall be of no force
            and effect and shall be severed and the remainder of the Agreement
            shall continue in full force and effect.
          </p>
          <p className="text-bold mt-5">13.4.10. Right to Waive.</p>
          <p className="mt-5">
            Any or all of the rights and limitations set forth in this
            Arbitration Agreement may be waived by the party against whom the
            claim is asserted. Such waiver shall not waive or affect any other
            portion of this Arbitration Agreement.
          </p>
          <p className="text-bold mt-5">13.4.11. Survival of Agreement. </p>
          <p className="mt-5">
            This Arbitration Agreement will survive the termination of your
            relationship with the Company.
          </p>
          <p className="text-bold mt-5">13.4.12. Emergency Equitable Relief.</p>
          <p className="mt-5">
            Notwithstanding the foregoing, either party may seek emergency
            equitable relief before a state or federal court in order to
            maintain the status quo pending arbitration. A request for interim
            measures shall not be deemed a waiver of any other rights or
            obligations under this Arbitration Agreement.
          </p>
          <p className="text-bold mt-5">
            13.4.13. Claims Not Subject to Arbitration.
          </p>
          <p className="mt-5">
            Notwithstanding the foregoing, claims of defamation, violation of
            the Computer Fraud and Abuse Act, and infringement or
            misappropriation of the other party's patent, copyright, trademark,
            or trade secrets shall not be subject to this Arbitration Agreement.
          </p>
          <p className="text-bold mt-5">13.4.14. Courts. </p>
          <p className="mt-5">
            In any circumstances where the foregoing Arbitration Agreement
            permits the parties to litigate in court, the parties hereby agree
            to submit to the personal jurisdiction of the courts located in
            Nevis, for such purpose.
          </p>
          <p className="text-bold mt-5">13.5 Attorneys' Fees and Costs. </p>
          <p className="mt-5">
            In addition to any relief, order, or award that is entered by an
            arbiter, or court as the case may be, any Party found to be the
            substantially losing Party in any dispute shall be required to pay
            the reasonable attorneys' fees and costs of any Party determined to
            be the substantially prevailing Party, and such losing Party, shall
            also reimburse or pay any of the arbitrator's fees and expenses
            incurred by the prevailing Party in any arbitration. In the context
            of this Agreement, reasonable attorneys' fees and costs shall
            include but not be limited to:
          </p>
          <p className="text-bold mt-5">13.5.1.</p>
          <p className="mt-5">
            legal fees and costs, the fees and costs of witnesses, accountants,
            experts, and other professionals, and any other forum costs incurred
            during, or in preparation for, a dispute;
          </p>

          <p className="text-bold mt-5">13.5.2. </p>
          <p className="mt-5">
            all of the foregoing whether incurred before or after the initiation
            of a Proceeding; and
          </p>
          <p className="text-bold mt-5">13.5.3. </p>
          <p className="mt-5">
            all such fees and costs incurred in obtaining Provisional Relief.
          </p>
          <p className="mt-5">
            It is understood that certain time entries that may appear in the
            billing records of such Party's legal counsel may be redacted to
            protect attorney-client or work-product privilege, and this will not
            prevent recovery for the associated billings.
          </p>
          <p className="text-bold mt-5">13.6. Third Party Beneficiaries.</p>
          <p className="mt-5">
            Except as limited by Section 13.7, this Agreement and the rights and
            obligations hereunder shall bind and inure to the benefit of the
            parties and their successors and permitted assigns. Nothing in this
            Agreement, expressed or implied, is intended to confer upon any
            person, other than the parties and their successors and permitted
            assigns, any of the rights hereunder.
          </p>
          <p className="text-bold mt-5">13.7. Entire Agreement.</p>
          <p className="mt-5">
            This Agreement and each of its exhibits or appendices, constitute
            and contain the entire agreement between the parties with respect to
            the subject matter hereof and supersedes any prior or
            contemporaneous oral or written agreements. Each party acknowledges
            and agrees that the other has not made any representations,
            warranties, or agreements of any kind, except as expressly set forth
            herein.
          </p>
          <p className="text-bold mt-5">13.8. Severability.</p>
          <p className="mt-5">
            If any provision of this Agreement (or any portion thereof) is
            determined to be invalid or unenforceable, the remaining provisions
            of this Agreement shall not be affected thereby and shall be binding
            upon the parties and shall be enforceable, as though said invalid or
            unenforceable provision (or portion thereof) were not contained in
            this Agreement.
          </p>
          <p className="text-bold mt-5">13.9. Assignment.</p>
          <p className="mt-5">
            You may not assign or transfer any rights hereunder without the
            prior written consent of the Company. Except as provided in this
            section, any attempts you make to assign any of your rights or
            delegate any of your duties hereunder without the prior written
            consent of the Company shall be null and void. The Company may
            assign this Agreement or any rights hereunder without consent.
          </p>
          <p className="mt-5">
            Company Contact Information. Questions can be directed to the
          </p>
          <p>Company at contact@algofame.org</p>
        </div>
      </section>
    </>
  );
}
