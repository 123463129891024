import React, { useEffect, useState, Fragment, useRef } from "react";
import Progress from "../Components/Progress";
import { getCountdownDate, proposalDate } from "../Controller/Countdown";
import { getAllProposals } from "../Lib/Propose";
import { Dialog, Transition } from "@headlessui/react";

export const Previous = () => {
  const [proposals, setProposals] = useState([]);
  const [showAllProposals, setShowAllProposals] = useState(false);
  const [txnsId, setTxnsID] = useState();

  const getProposals = async () => {
    const data = await getAllProposals();
    // console.log("test", data);
    const proposalsData = data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    // Calculate upvote for each proposal
    const updatedProposals = proposalsData.map((proposal) => {
      const up = parseInt(proposal.up);
      const down = parseInt(proposal.down);
      const result = up + down;
      if (!result) {
        const upvote = 0;
        const dnvote = 0;
        const date = proposalDate(proposal.endTime);
        return { ...proposal, upvote, date, dnvote };
      } else {
        const upvote = ((up / result) * 100).toFixed(2);
        const dnvote = ((down / result) * 100).toFixed(2);
        const date = proposalDate(proposal.endTime);
        return { ...proposal, upvote, date, dnvote };
      }
    });

    setProposals(updatedProposals);
  };

  // Function to toggle the visibility of all proposals
  const toggleAllProposals = () => {
    setShowAllProposals(!showAllProposals);
  };
  // Map only the required number of proposals based on showAllProposals state
  const displayedProposals = showAllProposals
    ? proposals
    : proposals.slice(0, 3);

  const [isOpenAdditionalProposals, setIsOpenAdditionalProposals] =
    useState(false);

  function openAdditionalProposalsModal() {
    setIsOpenAdditionalProposals(true);
  }

  function closeAdditionalProposalsModal() {
    setIsOpenAdditionalProposals(false);
  }

  useEffect(() => {
    getProposals();
  }, []);

  let [isOpen6, setIsOpen6] = useState(false);

  function openModal6() {
    setIsOpen6(false);
    setIsOpen6(true);
  }
  function closeModal6() {
    setIsOpen6(false);
  }

  const cancelButtonRef = useRef(null);

  return (
    <div className="mt-10">
      <p className="previous">Previous proposal</p>

      {displayedProposals.map((proposal, index) => (
        <div key={proposal.id} className="mt-5 vote-sec grid grid-cols-10">
          <div className="col-span-1 p-5 pt-5">
            <Progress
              percentup={proposal.upvote}
              percentdn={proposal.dnvote}
              bg="bg-secondary"
            />
          </div>
          <div className="col-span-9 p-5">
            <p className="vote-card-head">
              Ended {proposal.date}
              {proposal.status === "Successful" ? (
                <span className="text-secondary"> {proposal.status}</span>
              ) : (
                <span className="text-primary"> {proposal.status}</span>
              )}
            </p>{" "}
            {proposal.proposalType === "re" ? (
              <p className="vote-card-head mt-2">Referendum</p>
            ) : (
              <p className="vote-card-head mt-2">
                Proposal -{" "}
                {proposal.proposalType === "pa"
                  ? (Number(proposal.receiverAmount) * 10e-5).toFixed(2) +
                    " Algo"
                  : proposal.proposalType === "n"
                  ? (Number(proposal.receiverAmount) * 10e-1).toFixed(2) +
                    " FAME"
                  : 0}
              </p>
            )}
            <p
              className="vote-card-sub mt-5"
              style={{ wordWrap: "break-word" }}
            >
              {proposal.description}
            </p>
            {proposal.status === "Successful" ? (
              <p
                className=" text-secondary font-bold cursor-pointer"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                onClick={() => {
                  openModal6();
                  setTxnsID(proposal.txns);
                }}
              >
                ID : {proposal.txns}
              </p>
            ) : (
              <p
                className=" mt-2 text-left text-secondary cursor-pointer"
                style={{
                  fontSize: "12px",
                  color: "black",
                }}
              >
                {proposal.txns}
              </p>
            )}
          </div>
        </div>
      ))}

      {/* <div className="mt-5 vote-sec grid grid-cols-10">
        <div className="col-span-1"></div>
        <div className="col-span-9 p-5">
          <p className="vote-card-head">
            Ended 21 Feb 2023
            <span className="text-secondary"> Successful</span>
          </p>
          <p className="vote-card-head">NFT donation to nigeriafarmproj.algo</p>
          <p className="vote-card-sub mt-5">
            Sending funds for Ijede Farm purchase. Near the Lagos lagoon, the
            new Ijede Farm is intended to provide work for 20 people as well as
            pledging to commit 20% of operating profits back into the FA…
          </p>
        </div>
      </div> */}
      <p
        onClick={openAdditionalProposalsModal}
        className="mt-5 text-secondary more-proposal cursor-pointer"
      >
        More previous FAME proposals…
      </p>

      {/* iframe */}
      <Transition.Root show={isOpen6} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal6}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 ">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="text-center ">
                      <iframe
                        src={`https://testnet.algoexplorer.io/tx/${txnsId}`}
                        width={350}
                        height={500}
                      ></iframe>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={isOpenAdditionalProposals} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeAdditionalProposalsModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative top-10 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 ">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="text-center ">
                      {proposals.map((proposal, index) => (
                        <div
                          key={proposal.id}
                          className="mt-5 vote-sec grid grid-cols-10"
                        >
                          <div className="col-span-1 p-5 pt-5">
                            <Progress
                              percentup={proposal.upvote}
                              percentdn={proposal.dnvote}
                              bg="bg-secondary"
                            />
                          </div>
                          <div className="col-span-9 p-5">
                            <p className="vote-card-head">
                              Ended {proposal.date}{" "}
                              <span className="text-primary">
                                {proposal.status}
                              </span>
                            </p>
                            {proposal.proposalType === "re" ? (
                              <p className="vote-card-head mt-2">Referendum</p>
                            ) : (
                              <p className="vote-card-head mt-2">
                                Proposal -{" "}
                                {proposal.proposalType === "pa"
                                  ? (
                                      Number(proposal.receiverAmount) * 10e-5
                                    ).toFixed(2) + " Algo"
                                  : proposal.proposalType === "n"
                                  ? (
                                      Number(proposal.receiverAmount) * 10e-1
                                    ).toFixed(2) + " FAME"
                                  : 0}
                              </p>
                            )}
                            <p
                              className="vote-card-sub mt-5"
                              style={{ wordWrap: "break-word" }}
                            >
                              {proposal.description}
                            </p>

                            {proposal.status === "Successful" ? (
                              <p
                                className=" text-secondary font-bold cursor-pointer"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                onClick={() => {
                                  openModal6();
                                  setTxnsID(proposal.txns);
                                }}
                              >
                                ID : {proposal.txns}
                              </p>
                            ) : (
                              <p
                                className=" mt-2 text-left text-secondary cursor-pointer"
                                style={{
                                  fontSize: "12px",
                                  color: "black",
                                }}
                              >
                                {proposal.txns}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};
