import React from "react";
import { FadeIn } from "react-slide-fade-in";
import Img14 from "../Assets/Img/image14.png";
import Img1 from "../Assets/Img/image1.png";
import ProblemBox from "../Components/ProblemBox";
import SolutionBox from "../Components/SolutionBox";
import Roadmap from "../Assets/Img/roadmap.png";
import RoadmapMobile from "../Assets/Img/roadmap-mobile.png";
import Partner1 from "../Assets/Img/partner1.png";
import Partner2 from "../Assets/Img/partner2.png";
import Partner3 from "../Assets/Img/partner3.png";
import Partner4 from "../Assets/Img/partner4.png";
import Partner5 from "../Assets/Img/partner5.png";
import Partner6 from "../Assets/Img/partner6.png";
import Person1 from "../Assets/Img/person1.jpg";
import Person2 from "../Assets/Img/person2.png";
import Person3 from "../Assets/Img/person3.png";
import Person4 from "../Assets/Img/person4.png";
import Person5 from "../Assets/Img/person5.png";
import Person6 from "../Assets/Img/person6.png";
import Person81 from "../Assets/Img/person81.png";
import Person9 from "../Assets/Img/person9.png";
import Person10 from "../Assets/Img/person10.png";
import Person11 from "../Assets/Img/person11.png";
import Person12 from "../Assets/Img/person12.png";
import Person13 from "../Assets/Img/person13.png";
import Person14 from "../Assets/Img/person14.png";
import Person15 from "../Assets/Img/person15.png";
import Person16 from "../Assets/Img/person16.png";
import Person17 from "../Assets/Img/person17.png";
import Person18 from "../Assets/Img/person18.png";
import Person19 from "../Assets/Img/person19.png";
import Person20 from "../Assets/Img/person20.png";
import Person21 from "../Assets/Img/person21.png";

import Img19 from "../Assets/Img/image19.png";
import Team from "../Components/Team";
import Form from "../Components/Form";
import { useTimer } from "../Controller/Constants";
export default function About() {
  const { days, hours, minutes, seconds } = useTimer();
  return (
    <section>
      <section>
        <div
          className="isolate bg-white hero-about lg:bg-center md:bg-top bg-[url('./Assets/Img/about-bg.jpg')] bg-center"
          style={{ backgroundRepeat: "no-repeat" }}
        >
          <main className="" style={{ maxWidth: "1400px", margin: "0 auto" }}>
            <div className="">
              <div className="p-10 lg:p-20 md:p-20 ">
                <div>
                  <div className="pt-20">
                    <FadeIn
                      from="left"
                      positionOffset={400}
                      triggerOffset={200}
                      delayInMilliseconds={0}
                    >
                      <p className="text-white lg:pb-0 md:pb-0 pb-20 lg:pt-0 md:pb-0 pt-10 text-right hero-heading">
                        who we are.
                      </p>
                    </FadeIn>
                    <FadeIn
                      from="right"
                      positionOffset={400}
                      triggerOffset={200}
                      delayInMilliseconds={0}
                    >
                      <h6 className="hero-list mt-10 text-right text-white hidden md:block lg:block">
                        <span>work.</span>
                        <span> sell.</span>
                        <span> borrow.</span>
                        <span> earn.</span>
                        <span> CONNECT.</span>
                      </h6>
                    </FadeIn>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </section>
      <section
        className="lg:p-20 p-10 grid lg:grid-cols-2 md:grid-cols-2 gap-5"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <FadeIn
          from="left"
          positionOffset={400}
          triggerOffset={200}
          delayInMilliseconds={0}
        >
          <div className="pb-10 lg:pr-5 md:pr-5">
            <p className="text-bold">
              FAME is a Regenerative Finance (ReFi) initiative
            </p>
            <p className="text-thin">
              A new approach for solving systemic problems in the food chain and
              regenerating communities around it. FAME aims to move individuals
              from the traditional exploitative model to a decentralized web3
              ecosystem to provide autonomy, financial power, control, fair
              return and due recognition.
            </p>
          </div>
        </FadeIn>
        <FadeIn
          from="right"
          positionOffset={400}
          triggerOffset={200}
          delayInMilliseconds={0}
        >
          <div className="pb-10 lg:pl-5 md:pl-5">
            <p className="text-bold">FAME provides a social marketplace</p>
            <p className="text-thin">
              This empowers farmers, workers and consumers. Through technology
              and financial access the aim is to enable farmers to scale and
              escape from poverty, making them “famous” for their products,
              which become directly accessible to consumers who also benefit
              from better quality, fair-priced products.
            </p>
          </div>
        </FadeIn>
      </section>
      <section
        style={{
          background:
            "linear-gradient(63.54deg, #6E0289 16.59%, #00C3B3 88.13%)",
        }}
      >
        <div
          className="lg:p-20 p-10 grid lg:grid-cols-7 gap-10"
          style={{ maxWidth: "1400px", margin: "0 auto" }}
        >
          <img className="col-span-3" src={Img14} alt="" />
          <div className="col-span-2 pb-10">
            <ProblemBox />
            <p className="text-thin text-white mt-5">
              Developing countries face social marginalization and financial
              exclusion leading to poverty and food insecurity, and together
              with the rest of the world, are hostages of Climate Change effects
              that culminate in the degradation of natural resources, loss of
              biodiversity and scarcity of resources.
            </p>
          </div>
          <div className="col-span-2">
            <SolutionBox />
            <p className="text-thin text-white mt-5 ">
              Showcase services and products to support the African community of
              workers, drivers, farmers and consumers to collaborate without any
              middlemen or intermediaries. Access to advanced financial services
              at scale (lending, borrowing) building a multi-million-people
              “middle class”.
            </p>
          </div>
        </div>
      </section>
      <section
        className="lg:p-20 p-10"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <p className="text-heading">
          <span className="text-secondary">roadmap</span> project development
        </p>
        <img className="pt-20 lg:block md:block hidden" src={Roadmap} alt="" />
        <img
          className="pt-20 md:hidden lg:hidden sm:block"
          src={RoadmapMobile}
          alt=""
        />
      </section>
      <section
        style={{
          background:
            "linear-gradient(63.54deg, #6E0289 16.59%, #00C3B3 88.13%)",
        }}
      >
        <div style={{ maxWidth: "1400px", margin: "0 auto" }}>
          <div className="grid lg:grid-cols-3  gap-10 p-10 lg:p-20 md:p-20 content-center text-white">
            <div className="text-left">
              <FadeIn
                from="left"
                positionOffset={400}
                triggerOffset={200}
                delayInMilliseconds={0}
              >
                <ProblemBox />
                <p
                  className="mt-5 mb-5 md:mr-20 lg:mr-20 "
                  style={{
                    fontSize: "26px",
                    fontWeight: "600",
                    lineHeight: "33px",
                    letterSpacing: "0.6499999761581421px",
                    textAlign: "left",
                    color: "#595959;",
                  }}
                >
                  As a consumer, Olu can't :
                </p>
                <div className="text-left inline-block mb-3">
                  <span className="red-dot"></span>
                  <span className="text-white mt-0 ">
                    <span className="font-bold">
                      Limited Access to Quality Products:
                    </span>{" "}
                    Olu struggles to find affordable, nutritious, organic, and
                    ethically-sourced products in proximity, risking
                    malnutrition for his family.
                  </span>
                </div>
                <div className="text-left inline-block mb-3">
                  <span className="red-dot"></span>
                  <span className="text-white mt-0 ">
                    <span className="font-bold">Unreliable Local Markets:</span>{" "}
                    The local open markets in Olu's area are limited and don't
                    always guarantee the quality of produce.
                  </span>
                </div>
                <div className="text-left inline-block">
                  <span className="red-dot"></span>
                  <span className="text-white mt-0 ">
                    <span className="font-bold">Financial Exclusion:</span>{" "}
                    Despite his consistent income, Olu's banking services are
                    restrictive, denying him access to microcredits due to his
                    modest earnings.
                  </span>
                </div>
              </FadeIn>
            </div>
            <div className="text-left">
              <FadeIn
                from="left"
                positionOffset={400}
                triggerOffset={200}
                delayInMilliseconds={0}
              >
                <SolutionBox />
                <p
                  className=" mt-5 mb-5 md:mr-20 lg:mr-20"
                  style={{
                    fontSize: "26px",
                    fontWeight: "600",
                    lineHeight: "33px",
                    letterSpacing: "0.6499999761581421px",
                    textAlign: "left",
                  }}
                >
                  With FAME Olu can now:
                </p>

                <div className="text-left inline-block mb-3">
                  <span className="green-dot"></span>
                  <span className="text-white mt-0 ">
                    <span className="font-bold">
                      Direct Connection to Quality Producers:
                    </span>{" "}
                    Through FAME, Olu can directly purchase from local farmers,
                    ensuring he gets fresh, ethical, and organic produce at a
                    fair price.
                  </span>
                </div>
                <div className="text-left inline-block mb-3">
                  <span className="green-dot"></span>
                  <span className="text-white mt-0 ">
                    <span className="font-bold">
                      Strengthening Local Community:
                    </span>{" "}
                    By using FAME, Olu not only ensures better food for his
                    family but also supports and empowers his local community of
                    workers and farmers.
                  </span>
                </div>
                <div className="text-left inline-block mb-3">
                  <span className="green-dot"></span>
                  <span className="text-white mt-0 ">
                    <span className="font-bold">
                      Financial Inclusion with DEFI:
                    </span>{" "}
                    FAME leverages the power of Algorand's DEFI, allowing Olu to
                    earn passive income and access automated borrowing options,
                    promoting financial independence.
                  </span>
                </div>
              </FadeIn>
            </div>
            <div>
              <FadeIn
                from="right"
                positionOffset={400}
                triggerOffset={200}
                delayInMilliseconds={0}
              >
                <img
                  className="content-center"
                  src={Img19}
                  alt=""
                  style={{
                    borderWidth: "9px",
                    borderColor: "#ffffff",
                    borderRadius: "100%",
                  }}
                />
              </FadeIn>
            </div>
          </div>
        </div>
      </section>
      <section className="solution content-center">
        <div
          style={{ maxWidth: "1400px", margin: "0 auto" }}
          className="grid lg:grid-cols-3 gap-10 p-10 lg:p-20 md:p-20"
        >
          <div>
            <FadeIn
              from="left"
              positionOffset={400}
              triggerOffset={200}
              delayInMilliseconds={0}
            >
              <img
                className="content-center drop-shadow-[10px_10px_36px_rgba(0,0,0,0.306053)]"
                src={Img1}
                alt=""
                style={{
                  borderWidth: "9px",
                  borderColor: "#ffffff",
                  borderRadius: "100%",
                }}
              />
            </FadeIn>
          </div>
          <div className="text-left">
            <FadeIn
              from="right"
              positionOffset={400}
              triggerOffset={200}
              delayInMilliseconds={0}
            >
              <ProblemBox />
              <p
                className="text-white mt-5 mb-5 md:mr-20 lg:mr-20 solution-heading"
                style={{
                  fontSize: "26px",
                  fontWeight: "600",
                  lineHeight: "33px",
                  letterSpacing: "0.6499999761581421px",
                  textAlign: "left",
                }}
              >
                Emmanuel couldn't find delivery jobs so:
              </p>
              <div className="text-left inline-block mb-3">
                <span className="red-dot"></span>
                <span className="text-white mt-0 solution-text">
                  He relied on friends and family to find new customers.
                </span>
              </div>
              <div className="text-left inline-block mb-3">
                <span className="red-dot"></span>
                <span className="text-white mt-0 solution-text">
                  He waited for hours in front of shops hoping for work.
                </span>
              </div>
              <div className="text-left inline-block">
                <span className="red-dot"></span>
                <span className="text-white mt-0 solution-text">
                  He had no control over his salary or contract and depended on
                  centralized orgs such as Uber.
                </span>
              </div>
            </FadeIn>
          </div>
          <div className="text-left ">
            <FadeIn
              from="right"
              positionOffset={400}
              triggerOffset={200}
              delayInMilliseconds={0}
            >
              <SolutionBox />
              <p
                className="text-white mt-5 mb-5 md:mr-20 lg:mr-20 solution-heading"
                style={{
                  fontSize: "26px",
                  fontWeight: "600",
                  lineHeight: "33px",
                  letterSpacing: "0.6499999761581421px",
                  textAlign: "left",
                }}
              >
                With FAME Emmanuel can now:
              </p>

              <div className="text-left inline-block mb-3">
                <span className="green-dot"></span>
                <span className="text-white mt-0 solution-text">
                  Actively search out and take the better work opportunities.
                </span>
              </div>
              <div className="text-left inline-block mb-3">
                <span className="green-dot"></span>
                <span className="text-white mt-0 solution-text">
                  Access job 'on the go' - he's more flexible in his time and in
                  control.
                </span>
              </div>
              <div className="text-left inline-block mb-3">
                <span className="green-dot"></span>
                <span className="text-white mt-0 solution-text">
                  Able to negotiate better deals directly with farmers,
                  restaurants and consumers, building trust.
                </span>
              </div>
            </FadeIn>
          </div>
        </div>
      </section>
      <section className="bg-secondary ">
        <div
          style={{ maxWidth: "1400px", margin: "0 auto" }}
          className=" p-10 lg:p-20 md:p-20"
        >
          <p className="partner-text col-span-3">
            partner <br /> <span className="text-white">organizations</span>
          </p>
          <div className="grid lg:grid-cols-3 md:grid-cols-3 gap-10 pt-10">
            <div className="">
              <img src={Partner1} alt="" className="image" />
              <p className="subtitle mt-3">Algorand Foundation</p>
            </div>
            <div className="">
              <img src={Partner2} className="image" alt="" />
              <p className="subtitle mt-3">
                Blockchain Supply Chain Association - Global
              </p>
            </div>
            <div className="">
              <img src={Partner3} alt="" className="image" />
              <p className="subtitle mt-3">Algo Foundry</p>
            </div>
            <div className="">
              <img src={Partner4} alt="" className="image" />
              <p className="subtitle mt-3">LE 101</p>
            </div>
            <div className="">
              <img src={Partner5} alt="" className="image" />
              <p className="subtitle mt-3">Hôtel Entreprises</p>
            </div>
            <div className="">
              <img src={Partner6} alt="" className="image" />
              <p className="subtitle mt-3">Fabrique de Territoire</p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="p-10 lg:p-20 md:p-20"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <p className="text-heading">
          the FAME <span className="text-primary">team</span>
        </p>
        <div className="grid lg:grid-cols-3 pt-10 gap-10">
          <Team
            person="Bilal Brahim"
            position="CEO"
            picture={Person1}
            content1="Bilal, a philosopher trained at the renowned Sorbonne University of Paris, has an impressive career spanning over fourteen years in the fields of ethics and metaphysics teaching in both France and Australia."
            content2="In 2019, Bilal came across the innovative work of Silvio Micali, sparking an interest in the transformative potential of Algorand technology for global financial inclusion. Seizing this opportunity, he embarked on an entrepreneurial journey with his project 'FAME', which aims to use Algorand's technology to enhance financial inclusion and social impact for smallholder farmers, while also promoting food transparency for consumers."
            content3="In addition to his project, Bilal holds a crucial role as the Commissioner for Food Security at the International Emergency Management Organisation, as established by the UN Treaty Series I-42766. This position reflects Bilal's unwavering commitment to addressing pressing global issues, particularly in food security."
          />
          {/* <Team
            person="Tim O'Brien"
            position="CTO"
            picture={Person13}
            content1="Tim has 20 years experience as a architect consultant to Fortune 500 companies and has also founded 4 companies. Since 2018 Tim has been building on blockchain and pursuing his passion to democratize technology  and to see to it that anyone can  build blockchain and supply chain applications regardless of their background, technical proficiency  or where they were born."
            content2=""
            content3=""
          /> */}
          <Team
            person=" Lauren Basler"
            position="Head of U.S Operation"
            picture={Person18}
            content1="Lauren is a recent graduate from Tuft's University where she obtained a Master's in International Business and a MS in Food Policy and Programs.  She is passionate about the intersection of food, technology, impact and finance, especially in relation to technological benefits provided by Blockchain and crypto."
            content2="Lauren has five years experience working in Strategy, Operations and Program Management for a nonprofit organization in the US focused on food donation and food waste prevention, with logistics and transportation being the primary challenge."
            content3="She is excited by FAME's mission and to provide guidance and advisory in consideration of positive impact that FAME could bring by expanding into the United States.  "
          />
          <Team
            person="Temitope BAtly"
            position="CMO"
            picture={Person21}
            content1="Batly is a Market Research Consultant and a passionate advocate for the UN Sustainable Development Goals (SDGs) in Africa who is intrigued by the transformational possibilities of blockchain technology, how it can transform various sectors and impact Africans positively."
            content2="Batly has more than 8 years hands-on experience under her belt cutting across various tech and non-tech industries. Additionally, Batly is a member of Forbes Blk Community and recognised by CryptoTV Plus as top 40 African women in Blockchain."
            content3="Outside the blockchain world, she is an addicted and dedicated volunteer who has directly/indirectly impacted lives of Internally Displaced Girls, out of school children, and mentored young youths who need guidance transitioning into the tech industry. She is thrilled about what the team is building at FAME and the developmental effect it would have on Farmers in Africa."
          />
          <Team
            person=" Christina Stelzl"
            position="Head of Supply Chain"
            picture={Person19}
            content1="Christina Stelzl is a seasoned professional specialized in business strategy and food supply chain management. With 20 years of experience in the food supply chain sector, her career began in logistics and retail business, transitioning later to significant roles in wholesale and retail companies in Austria and Germany."
            content2="In 2023, she founded her own trading and consulting firm based in Austria, offering expert consultation and project execution along the food supply chain to assist businesses in refining their purchasing or sales strategies and exploring new markets."
            content3="Christina earned her MBA in Management and Business Strategy from Hochschule für Angewandte Wissenschaften, Munich, with a thesis on sustainability labels in the German food industry, and a Bachelor of Arts in Business with a concentration in Logistics and Supply Chain Management from Fachhochschule des BFI, Vienna. Fluent in German and English, she excels in fostering strategic partnerships and brings expertise in ecological agriculture and supply chain management."
            content4=""
          />

          <Team
            person="Kingsley Chukwuonyia"
            position="FAME Farm owner, Fame Foundation (Lagos)"
            picture={Person4}
            content1="Kingsley chukwuonyia is a chemist ,graduated from the University of science and technology in Enugu state of Nigeria, ESUT."
            content2="Have worked with First Oil as a quality control personnel, worked with Blueveld Nig Ltd as an NDT personnel, later engaged in business of communications, such as phones and accessories, presently working as a farmer in Akure Ondo state, and the founder of Kingsley fame foundation."
            content3=""
          />

          <Team
            person="Judith"
            position="Community Manager in Kenya"
            picture={Person17}
            content1="My name is Judith from kenya and I am a Farmer,I am hard working and dedicated young farmer despite having a rare neurological condition(Myasthenia gravis)that doesn't stop me from the love I have for the land."
            content2="I was born and raised into a farming family and from a young age I was taught  the importance of taking care of the land and everything on it and I fell in love with the life of a Farmer which am doing upto now."
            content3=""
          />
          <Team
            person="Carlos Lima"
            position="FAME Ambassador for Portugal"
            picture={Person20}
            content1="Since 1999, Carlos Lima has taught Physics and Chemistry, earning his degree from the University of Minho in Braga, Portugal. He's spearheaded numerous initiatives to popularize science and has served as the vice-president of the Minho Scientific Astronomy Association since 2018."
            content2=" In addition to his academic pursuits, Carlos has been an advocate for blockchain technology since 2020, focusing especially on the Algorand project. As of August 2023, he is the FAME Ambassador for Portugal and Portuguese-speaking nations."
            content3=""
          />
          <Team
            person="Emmanuel Charles"
            position="Blockchain  Developer"
            picture={Person6}
            content1="Emmanuel Charles is a Certified Design Engineer and Software Developer with 4 years experience building crafted usable and scalable products in SAAP, SAAS, WEB2/WEB3, Mobile Solution and others  for companies to accelerate their growth."
            content2="Emmanuel's programming skills includes Java, Reach Blockchain, Dart(Flutter), Python(Automation) and JavaScript(Native) highly skilled in Design solution, Mobile app development , Data visualization, Blockchain development, Ethical hacking and Hardware Automation, currently building at ALGOFAME, he lives in Lagos, Nigeria."
            content3=""
          />
          <Team
            person="Abdulrazaq Oladapo"
            position="Full stack developer"
            picture={Person81}
            content1="Abdulrazaq is a Mechatronics Engineer and a senior software developer with more than 6 years experience and currently working alongside the executive team of ALGOFAME. "
            content2="Abdulrazaq specializes in mobile application development technology (Flutter) and is responsible for building FAME web3 farmers social solution and also an active enthusiast on ALGORAND blockchain."
            content3="Abdulrazaq has many computer programming skills which include Javascript(NodeJs), Dart(Flutter), C++(IOT) and Java(Native) and has built multiple applications on these platform languages. He currently lives in Nigeria, Ekiti State."
          />
          <Team
            person="Aholu Samuel"
            position="Blockchain Developer"
            picture={Person9}
            content1="Samuel is a Tech-savvy Web Developer with over 4 years experience, who contributes specially in search engine optimization -  to launch custom-tailored code for websites presence, detailed understanding of hands-on approach to customizing web presence strategy. Resourceful navigation of intricate factors governing web planning, security, Hosting and SEO."
            content2="Samuel's programming skills includes CSS, JavaScript (Vanilla, React & Nodejs), PHP (Laravel), C++(IOT). He currently lives in Ekiti State, Nigeria."
            content3=""
          />
          <Team
            person="Chris Dawson"
            position="Graphic Designer"
            picture={Person10}
            content1="Chris runs his own branding and web-design studio in the UK - tallhat.com. With a degree in graphic design he worked for a large digital agency in London as 'Head of Design', producing creative work for brands such as Guinness, Peugeot and Microsoft. He is highly visual and ideas-led, working with organizations to help communicate their offerings to customers. He develops brands, marketing materials and designs websites for a wide variety of clients."
            content2="Chris discovered Algorand in early 2021 and has been exploring a few project ideas, recently launching SweeperKeeper - an unofficial World Cup NFT project - to test the water."
            content3=""
          />

          {/* <Team
            person="Eyitayo Anjorin"
            position="Head of Nigeria"
            picture={Person11}
            content1="Eyitayo Anjorin is a seasoned professional with a background in the blockchain industry, including experience as a community manager, blockchain engineer, and blockchain advocate."
            content2="He has been active in the industry since 2016 and has a strong understanding of both the technical and business aspects of blockchain technology. In addition to his expertise in blockchain, Eyitayo is proficient in both writing and coding. He is a well-rounded and highly skilled individual who is dedicated to driving success within the blockchain industry."
            content3=""
          /> */}
        </div>
      </section>
      <section className=" bg-tertiary">
        <div
          className="p-10 lg:p-20 md:p-20"
          style={{ maxWidth: "1400px", margin: "0 auto" }}
        >
          <p className="text-heading text-white">
            the fame <span className="text-secondary">advisors</span>
          </p>
          <div className="grid lg:grid-cols-3 pt-10 gap-10 text-grey">
            <Team
              person="Liran Peretz"
              position="Agritech expert"
              picture={Person15}
              content1="He is the co-founder and CEO of Alty, the all-in-one platform powering the world’s best crypto communities."
              content2="Before founding Alty, he worked in the digital finance & gaming sectors and oversaw the finance department at Playtech and managed the development of skilled teams in Antigua and the Philippines. He is an early crypto adopter, entering the space in 2011. Over the past 11 years, he has been actively engaged in the cryptocurrency community as a supporter and investor.
              Beyond his interests in crypto and finance, he has deep roots in commercial agriculture. Prior to his crypto and tech roles, he was a prominent figure in the regional agriculture segment, known for introducing Israeli drip and fertigation technology systems across the Caribbean region."
              content3="He is passionate about community development projects, the implementation of new technologies, and kitesurfing."
            />
            <Team
              person="Mokua (Erickson) Mogendi"
              position="Agritech expert"
              picture={Person16}
              content1="He believes technology can improve food security world wide, a generalist in Agricultural sector with all round skills in production in various enterprises within farming."
              content2="Livestock management,Crop management, Aquaculture management above all farm planning,budgeting and implementation.
              With a well resource mobilization and good structured agricultural policies, he believes we are able to feed ourselves without struggle."
              content3="Vertical farming, Aquaponics,Hydroponics,Greenhouse, among others can take us there."
            />
            <Team
              person="Andrea Frosinini"
              position="Supply Chain expert"
              picture={Person14}
              content1="I am a Senior Trade Finance professional, who mainly held this position in corporate  contexts, with a profound passion for innovation and digitisation. I recently had the opportunity to look at my job under a futuristic perspective since, in January 2020, I was asked to join Hyperledger community as Chairperson of Hyperledger Trade Finance SIG with the aim at offering a contribution to the industry modernization and development of new products exploiting on blockchain and DLTs, more in general. So far, I have managed to create a sound, cohesive network of professionals and companies operating in the field of enterprise blockchain solutions for Digital Trade & Supply Chain Finance, that is now giving the rise to some interesting projects and PoCs, built on different blockchain networks."
              content2="In late september 2021, I was hired by XDC Foundation as Business Development Manager. In this position, I managed to expand the community of developers and also gave rise to a series of project under the umbrella of several international institutions such as ITFA, with its, DNI-Digital Negotiable Initiative and TFDI  and TradeFinance Distribution Initiative,  ICC Singapore DSI-Digital Standards Initiative and BAFT in the US."
              content3="This has so far allowed me to further develop my experience of matters related not only to trade finance instruments and procedures, but, most importantly, on how these are being impacted by current business dynamics and digital transformation, with an eye always set on the market trends and developments at global scale."
            />
            <Team person="MG" position="Technology Advisor" picture={Person5} />
          </div>
        </div>
        <div className="pt-10 pb-10"></div>
      </section>
      {/* <section>
        <Form />
      </section> */}
    </section>
  );
}
