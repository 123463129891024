import React, { useEffect, useState, Fragment, useRef } from "react";
import { useWallet } from "@txnlab/use-wallet";
import { RiArrowUpFill } from "react-icons/ri";
import { RiArrowDownFill, RiArrowUpDownFill } from "react-icons/ri";
import { FiClock } from "react-icons/fi";
import { AiOutlineAreaChart } from "react-icons/ai";
import { algosdk, client, app_id, fame, duration } from "../utils";
import Loading from "../Assets/loading.gif";
import { useTimer } from "../Controller/Constants";
import { Dialog, Transition } from "@headlessui/react";
import { TiWarning } from "react-icons/ti";
import { ImCancelCircle } from "react-icons/im";
import { HiOutlineClipboardDocument } from "react-icons/hi2";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import {
  doc,
  collection,
  setDoc,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";

import Progress from "./Progress";
import { getTimeUsed, threshold } from "../Controller/Countdown";
import { getAllDescriptions, updateDescription } from "../Lib/Description";
import Duration from "./Duration";

export default function Current({ type }) {
  //Get User Wallet Address from Local Storage
  const [address, setAddress] = useState("CONNECT");
  const { activeAddress, signTransactions } = useWallet();
  const [txnsId, setTxnsId] = useState(false);
  const [warning, setWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [process, setProcess] = useState("");
  const [details, setDetails] = useState("");
  const [voteType, setVoteType] = useState("");

  const account = activeAddress;

  const {
    uv,
    dv,
    pv,
    p,
    days,
    hours,
    minutes,
    seconds,
    stakedAmount,
    algouBalance,
    walletBalance,
    lastVote,
    endTime,
    duration,
    proposal_Type,
    totalStake,
  } = useTimer();

  //Modal for Donation Success
  let [isOpen2, setIsOpen2] = useState(false);

  function openModal2() {
    setIsOpen2(true);
  }
  function closeModal2() {
    setIsOpen2(false);
  }

  //Modal for Connect Wallet Warning
  let [isOpen4, setIsOpen4] = useState(false);

  function openModal4() {
    setIsOpen4(true);
  }
  function closeModal4() {
    setIsOpen4(false);
  }

  //Modal for Transaction Cancelled
  let [isOpen5, setIsOpen5] = useState(false);

  function openModal5() {
    setIsOpen5(true);
  }
  function closeModal5() {
    setIsOpen5(false);
  }

  let [isOpen6, setIsOpen6] = useState(false);

  function openModal6() {
    setIsOpen6(true);
  }
  function closeModal6() {
    setIsOpen6(false);
  }
  const cancelButtonRef = useRef(null);

  const voteTxn = async (sender, index, txnType, assets, accounts, fame) => {
    setLoading(true);
    if (!account) {
      setWarning("Please Connect Wallet");
      setLoading(false);
      setLoading(false);
      openModal4(true);
    } else if (stakedAmount <= 0) {
      setWarning("You have to stake. Your stake is your voting Power!");
      setLoading(false);
      openModal4(true);
    } else if (algouBalance < 0.005) {
      setWarning("Not enough Algo for transaction fee!");
      setLoading(false);
      openModal4(true);
    } else if (walletBalance < 0.05) {
      setWarning("Not enough FAME  for transaction fee!");
      setLoading(false);
      openModal4(true);
    } else if (lastVote >= endTime - duration) {
      setWarning("You cannot vote more than once");
      setLoading(false);
      openModal4(true);
    } else {
      try {
        const appArgs = [];
        appArgs.push(
          new Uint8Array(Buffer.from(txnType)) //noop
        );
        let params = await client.getTransactionParams().do();
        params.fee = 1000;
        params.flatFee = true;

        // create unsigned asset transfer transaction
        let attxn1 = algosdk.makeAssetTransferTxnWithSuggestedParams(
          sender,
          algosdk.getApplicationAddress(index),
          undefined,
          undefined,
          1,
          undefined,
          fame,
          params
        );

        // create unsigned noop transaction
        let nptxn = algosdk.makeApplicationNoOpTxn(
          sender,
          params,
          index,
          appArgs,
          accounts,
          undefined,
          assets
        );

        let txns = [attxn1, nptxn];
        txns = algosdk.assignGroupID(txns);

        // Sign the transactions together using the wallet
        const encodedTransaction1 = algosdk.encodeUnsignedTransaction(attxn1);
        const encodedTransaction2 = algosdk.encodeUnsignedTransaction(nptxn);

        const signedTransactions = await signTransactions([
          encodedTransaction1,
          encodedTransaction2,
        ]);

        let tx = await client.sendRawTransaction(signedTransactions).do();
        console.log("Noop + Asset Transfer Transaction : " + tx.txId);

        // Wait for transaction to be confirmed
        const confirmedTxn = await algosdk.waitForConfirmation(
          client,
          tx.txId,
          4
        );
        console.log("confirmed" + confirmedTxn);

        //Get the completed Transaction
        console.log(
          "Transaction " +
            tx.txId +
            " confirmed in round " +
            confirmedTxn["confirmed-round"]
        );

        setTxnsId(tx.txId);
        setLoading(false);
        openModal2(true);
      } catch (err) {
        console.log(err);
        if (
          err.message.includes("Transaction Request Rejected") ||
          err.message.includes("Confirmation Failed")
        ) {
          setLoading(false);
          openModal5(true);
        } else {
          setLoading(false);
          setWarning("Network Error. Please Try Again!");
          openModal4(true);
        }
      }
    }
  };

  const upvote = async () => {
    await setVoteType("u");
    await voteTxn(account, app_id, "u", undefined, undefined, fame);
  };

  const downvote = async () => {
    await setVoteType("d");
    await voteTxn(account, app_id, "d", undefined, undefined, fame);
  };

  // GetCountDownDate
  const result = uv + dv;
  let up, down;
  if (!result) {
    up = 0;
    down = 0;
  } else {
    up = ((uv / result) * 100).toFixed(2);
    down = ((dv / result) * 100).toFixed(2);
  }

  //GetCountDownDuration
  const voteDurationCount = getTimeUsed(endTime, duration);

  //End of Cool Down Time
  const endOfCoolDown = endTime + duration;

  const thresholdReach = threshold(uv, dv, totalStake).toFixed(2);
  let thresholdColor;
  if (thresholdReach >= 100) {
    thresholdColor = "bg-secondary";
  } else {
    thresholdColor = "bg-primary";
  }

  let thresholdPercent;
  if (thresholdReach >= 100) {
    thresholdPercent = "Passed";
  } else {
    thresholdPercent = thresholdReach + "%";
  }

  const getDescriptions = async () => {
    const data = await getAllDescriptions();
    const descriptionData = data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    const updatedDescription = descriptionData.map((description) => {
      const long_desc = description.long_desc;
      const short_desc = description.short_desc;
      const wallet = description.wallet;
      const date = parseInt(description.date);

      return { ...description, date, long_desc, short_desc, wallet };
    });
    const firstDescription = updatedDescription[0];
    console.log(updatedDescription);
    setDetails(firstDescription);
  };

  useEffect(() => {
    getDescriptions();
  }, []);

  const modules = {
    toolbar: false, // Show or hide the toolbar based on isEditMode
  };

  const quillStyles = {
    border: "none",
    boxShadow: "none",
    outline: "none",
  };

  return (
    <>
      {loading && (
        <div className="top-0 left-0 w-full h-full z-50 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-100">
          <div
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            style={{
              backgroundColor: "white",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "2",
              height: "180px",
              width: "300px",
              justifyContent: "center",
            }}
          >
            <img src={Loading} alt="loading" className="flex ml-20" />
            <div>
              <p className="text-center">{process}</p>
            </div>
          </div>
        </div>
      )}
      <div className="col-span-3" style={{ display: type }}>
        <p className="text-black sec-two-head">
          Giving ownership to the people
        </p>
        <p className="text-secondary sec-two-sub">Vote on current proposal</p>
        <div className="mt-10 vote-sec">
          <div className="tape bg-secondary" style={{ height: "25px" }}></div>
          <div className="grid grid-cols-10 ">
            <div className="lg:col-span-2 col-span-3 lg:p-10 p-5 inline-flex item-center ">
              <div>
                <Duration percent={voteDurationCount} bg="bg-primary" />
                <FiClock className="mt-2" />
              </div>
              <div className="lg:ml-5 ml-3">
                <Progress percentup={up} percentdn={down} bg="bg-secondary" />
                <RiArrowUpDownFill className="mt-2" />
              </div>
              <div className="lg:ml-5 md:ml-5 ml-3">
                <Duration percent={thresholdReach} bg={thresholdColor} />
                <AiOutlineAreaChart className="mt-2" />
              </div>
            </div>
            {proposal_Type === "re" ? (
              <div className="lg:col-span-8 col-span-7 lg:p-10 p-5">
                <p className="vote-card-head">Referendum</p>
                <p className="vote-card-sub mt-3">{p}</p>
                <div className="grid lg:grid-cols-2 text-left lg:mt-5 mt-5">
                  <p className="lg:col-span-2">
                    {/* Showing the remaining time for voting */}
                    {days} Days : {hours} Hours : {minutes} Minutes : {seconds}{" "}
                    Seconds
                  </p>
                  <div className="lg:col-span-6 grid grid-cols-2">
                    <p className=" lg:mt-5 mt-3">
                      Yes: {Number(uv).toFixed(2)}
                    </p>
                    <p className=" lg:mt-5 mt-3">No: {Number(dv).toFixed(2)}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="lg:col-span-8 col-span-7 lg:p-10 p-5">
                <p className="vote-card-head">Description</p>
                <p className="vote-card-sub mt-3">{p}</p>
                <p className="vote-card-sub mt-3">
                  <span className="text-bold">Description Value - </span>

                  {/* Showing the proposal value weather Fame or Algo */}
                  {proposal_Type === "pa"
                    ? (pv * 10e-5).toFixed(2) + " Algo"
                    : proposal_Type === "n"
                    ? (pv * 10e-1).toFixed(2) + " FAME"
                    : 0}
                </p>
                <div className="grid lg:grid-cols-2 text-left lg:mt-5 mt-5">
                  <p className="lg:col-span-2">
                    {/* Showing the remaining time for voting */}
                    {days} Days : {hours} Hours : {minutes} Minutes : {seconds}{" "}
                    Seconds
                  </p>
                  <div className="lg:col-span-6 grid grid-cols-2">
                    <p className=" lg:mt-5 mt-3">Up: {Number(uv).toFixed(2)}</p>
                    <p className=" lg:mt-5 mt-3">
                      Down: {Number(dv).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          {proposal_Type === "re" ? (
            <div className="pt-5 lg:p-10 lg:pt-0 p-5 grid lg:grid-cols-10   vote">
              <p className="pt-3 lg:col-span-2 md:col-span-2 col-span-3 mb-3 text-left">
                VOTE:
              </p>
              <div className="lg:col-span-7 grid grid-cols-2 gap-">
                <button
                  onClick={upvote}
                  className="vote-icon  lg:col-span-3.5   inline-flex item-center ml-3 items-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 shadow-lg text-white font-bold py-2 px-4 rounded-lg"
                >
                  <span className="text-secondary">YES</span>
                  <RiArrowUpFill className=" bg-secondary ml-2  rounded-full" />
                </button>

                <button
                  onClick={downvote}
                  className="vote-icon lg:col-span-3.5  inline-flex item-center ml-3 items-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 shadow-lg text-white font-bold py-2 px-4 rounded-lg"
                >
                  <span className="text-primary">NO</span>
                  <RiArrowDownFill className=" bg-primary ml-2 rounded-full" />
                </button>
              </div>
            </div>
          ) : (
            <div className="pt-5 lg:p-10 lg:pt-0 p-5 grid lg:grid-cols-10 vote">
              <p className="pt-3 lg:col-span-2 md:col-span-2 col-span-3 mb-3 text-left">
                VOTE:
              </p>
              <div className="lg:col-span-7 grid grid-cols-2 gap-">
                <button
                  onClick={upvote}
                  className="vote-icon  lg:col-span-3.5   inline-flex item-center ml-3 items-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 shadow-lg text-white font-bold py-2 px-4 rounded-lg"
                >
                  <span className="text-secondary">UP</span>
                  <RiArrowUpFill className=" bg-secondary ml-2  rounded-full" />
                </button>

                <button
                  onClick={downvote}
                  className="vote-icon lg:col-span-3.5  inline-flex item-center ml-3 items-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 shadow-lg text-white font-bold py-2 px-4 rounded-lg"
                >
                  <span className="text-primary">DOWN</span>
                  <RiArrowDownFill className=" bg-primary ml-2 rounded-full" />
                </button>
              </div>
            </div>
          )}
          <div className="pb-5 grid  pl-5 lg:pl-10">
            <p className="font-semibold flex">FEE : 0.01 $FAME</p>
            <div className="inline-flex">
              <FiClock className="mt-1" />
              <span className="ml-2"> - Duration</span>
            </div>
            <div className="grid lg:grid-cols-0 md:grid-cols-0 lg:flex md:flex">
              <div className="inline-flex col-span-1">
                <RiArrowUpDownFill className="mt-1" />
                <p className="ml-2"> - Votes : </p>
              </div>
              <div className="col-span-4 " style={{ alignItems: "" }}>
                <div className="inline-flex">
                  <div className="bg-secondary float-left lg:ml-2 md:ml-2 h-3 w-3 mt-1.5"></div>
                  <p className="ml-2">- Up Votes</p>
                </div>
                <div className="inline-flex">
                  <div className="bg-black lg:ml-10 md:ml-10 ml-5 h-3 w-3 mt-1.5"></div>
                  <p className="ml-2">- Down Votes</p>
                </div>
              </div>
            </div>
            <div className="inline-flex">
              <AiOutlineAreaChart className="mt-1" />
              <span className="ml-2"> - Threshold : {thresholdPercent}</span>
            </div>
          </div>
        </div>
        <p className="text-left mt-10 text-black sec-two-sub  ">
          Proposal Pitch
        </p>
        <div className="mt-3 vote-sec">
          <div className="tape bg-secondary" style={{ height: "25px" }}></div>
          <div className=" " style={quillStyles}>
            <ReactQuill
              style={{ outline: "none" }}
              value={details.long_desc}
              readOnly={true}
              modules={modules}
            />
          </div>
        </div>
      </div>

      {/* Vote Success */}
      <Transition.Root show={isOpen2} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal2}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="text-center">
                      <BsFillCheckCircleFill className="text-secondary donate-check inline-block" />
                      <p className="mt-3 donate-confirmed">
                        Successfully Voted !
                      </p>
                      <div className="flex">
                        <p
                          className="mt-1"
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Transaction Id :{" "}
                          <span
                            onClick={openModal6}
                            className="text-secondary cursor-pointer"
                          >
                            {txnsId}
                          </span>
                        </p>
                        <CopyToClipboard text={txnsId}>
                          <button className="inline-flex ml-3 item-center transition ease-in-out hover:text-secondary text-white hover:bg-white hover:outline delay-150 hover:-translate-y-1 hover:scale-110 duration-300 rounded-lg px-2 py-1 text-base font-semibold leading-7 text-white bg-secondary ring-1 ring-gray-900/10 hover:ring-gray-900/2">
                            <p>Copy</p>
                            <HiOutlineClipboardDocument
                              style={{ fontSize: "20px" }}
                              className="ml-2 mt-1  text-center"
                            />
                          </button>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Warning */}
      <Transition.Root show={isOpen4} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal4}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="text-center">
                      <TiWarning className="text-yellow-500 donate-check inline-block" />
                      <p className="mt-3 donate-confirmed">{warning}</p>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Transaction cancelled */}
      <Transition.Root show={isOpen5} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal5}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="text-center">
                      <ImCancelCircle className="text-red-500 donate-check inline-block" />
                      <p className="mt-3 donate-confirmed">
                        Transaction Cancelled
                      </p>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* iframe */}
      <Transition.Root show={isOpen6} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal6}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 ">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="text-center ">
                      <iframe
                        src={`https://algoexplorer.io/tx/${txnsId}`}
                        width={350}
                        height={500}
                      ></iframe>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
