function getCountdownDate(days, hours, minutes, seconds) {
  // Parse the countdown string into individual units

  // Get the current date and time
  const now = new Date();

  // Calculate the countdown date
  const countdownDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + days + 1,
    now.getHours() + hours,
    now.getMinutes() + minutes,
    now.getSeconds() + seconds
  );

  // Extract the year, month, and day components
  const year = countdownDate.getFullYear();
  const month = countdownDate.toLocaleString("default", { month: "short" });
  const day = countdownDate.getDate();

  // Create a formatted date string without the time part
  const formattedDate = `${day} ${month} ${year}`;

  return formattedDate;
}
export { getCountdownDate };

// Get the current timestamp in milliseconds
// const startTime = Date.now();

// Calculate the end timestamp by adding 1000 seconds (1000 * 1000 milliseconds)

function getTimeUsed(endTime, duration) {
  const currentTime = Date.now() / 1000;
  const startTime = endTime - duration;

  // Ensure the current time is within the specified range
  if (currentTime >= startTime && currentTime <= endTime) {
    const elapsedTime = currentTime - startTime;
    const totalTime = endTime - startTime;

    const percentageUsed = (elapsedTime / totalTime) * 100;
    const peercentageRemaining = 100 - percentageUsed;
    return peercentageRemaining.toFixed(2); // Round to 2 decimal places
  } else if (currentTime > endTime) {
    return 0; // If end time has passed, return 100%
  } else {
    return 100; // If current time is before start time, return 0%
  }
}

export { getTimeUsed };

function endOfCool(endTimestamp) {
  const now = Math.floor(Date.now() / 1000); // Current Unix timestamp in seconds
  const remainingTime = endTimestamp - now;

  if (remainingTime <= 0) {
    return "Countdown complete!";
  } else {
    const hours = Math.floor(remainingTime / 3600);
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = remainingTime % 60;

    const formattedCountdown = `${hours} Hrs : ${minutes} Mins : ${seconds} Secs`;
    return formattedCountdown;
  }
}

export { endOfCool };

function threshold(upvote, downvote, totalStake) {
  const totalVotes = upvote + downvote;
  const threshold = ((30 / 100) * totalStake).toFixed(2);
  const percentage = Number(totalVotes / threshold) * 100;
  return percentage;
}
export { threshold };

function thresholds(upvote, downvote, totalStake) {
  const totalVotes = upvote + downvote;
  const threshold = ((30 / 100) * totalStake).toFixed(2);
  const percentage = Number(totalVotes / threshold) * 100;
  return percentage;
}
export { thresholds };

function proposalDate(unix) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(unix * 1000);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

export { proposalDate };
