import React from "react";
import { FadeIn } from "react-slide-fade-in";
import Img18 from "../Assets/Img/image18.png";
import ProblemBox from "./ProblemBox";
import SolutionBox from "./SolutionBox";
export default function Solution() {
  return (
    <section
      style={{
        background: "linear-gradient(63.54deg, #6E0289 16.59%, #00C3B3 88.13%)",
      }}
    >
      <div
        className="grid lg:grid-cols-3  gap-10 p-10 lg:p-20 md:p-20 content-center"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <div>
          <FadeIn
            from="left"
            positionOffset={400}
            triggerOffset={200}
            delayInMilliseconds={0}
          >
            <img
              className="content-center"
              src={Img18}
              alt=""
              style={{
                borderWidth: "9px",
                borderColor: "#ffffff",
                borderRadius: "100%",
              }}
            />
          </FadeIn>
        </div>
        <div className="text-left">
          <FadeIn
            from="right"
            positionOffset={400}
            triggerOffset={200}
            delayInMilliseconds={0}
          >
            <ProblemBox />
            <p
              className="text-white mt-5 mb-5 md:mr-20 lg:mr-20"
              style={{
                fontSize: "26px",
                fontWeight: "600",
                lineHeight: "33px",
                letterSpacing: "0.6499999761581421px",
                textAlign: "left",
              }}
            >
              Amina can't access financial services:
            </p>
            <div className="text-left inline-block mb-3">
              <span className="red-dot"></span>
              <span className="text-white mt-0">
                <span className="font-bold">Labor Shortage:</span> Unable to
                find skilled labor locally, Amina often relied on her children
                to help with farm tasks, impacting their education and her
                farm's productivity.
              </span>
            </div>
            <div className="text-left inline-block mb-3">
              <span className="red-dot"></span>
              <span className="text-white mt-0">
                <span className="font-bold">Dependence on Middlemen:</span>{" "}
                Without a direct link to consumers, Amina was at the mercy of
                intermediaries who pressured her to slash her prices, minimizing
                her profits.
              </span>
            </div>
            <div className="text-left inline-block">
              <span className="red-dot"></span>
              <span className="text-white mt-0">
                <span className="font-bold">Credit Constraints:</span>{" "}
                Traditional banks were out of reach for Amina, making it hard to
                secure loans and invest in scaling up her farm business.
              </span>
            </div>
          </FadeIn>
        </div>
        <div className="text-left ">
          <FadeIn
            from="right"
            positionOffset={400}
            triggerOffset={200}
            delayInMilliseconds={0}
          >
            <SolutionBox />
            <p
              className="text-white mt-5 mb-5 md:mr-20 lg:mr-20"
              style={{
                fontSize: "26px",
                fontWeight: "600",
                lineHeight: "33px",
                letterSpacing: "0.6499999761581421px",
                textAlign: "left",
              }}
            >
              With FAME, Now Amina can:
            </p>

            <div className="text-left inline-block mb-3">
              <span className="green-dot"></span>
              <span className="text-white mt-0">
                <span className="font-bold">Local Job Postings:</span>
                Through FAME, Amina can instantly post job listings, alerting
                workers and drivers in her vicinity. This reduces her dependence
                on child labor and boosts productivity.
              </span>
            </div>
            <div className="text-left inline-block mb-3">
              <span className="green-dot"></span>
              <span className="text-white mt-0">
                <span className="font-bold">Direct-to-Consumer Marketing:</span>{" "}
                FAME gives Amina a platform to showcase her ethical, organic
                produce, connecting her directly with a wider audience, and
                eliminating middlemen's unfair cuts.
              </span>
            </div>
            <div className="text-left inline-block mb-3">
              <span className="green-dot"></span>
              <span className="text-white mt-0">
                <span className="font-bold">Financial Empowerment:</span> Not
                only can Amina earn passive income from her FAME savings, but
                she can also access loans, empowering her to make significant
                investments in her farm.
              </span>
            </div>
          </FadeIn>
        </div>
      </div>
    </section>
  );
}
